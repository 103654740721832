import React, { useState, useEffect, useCallback } from 'react';
import './Carousel.css';

const Carousel = ({
  slides,
  showButton,
  showIndicator,
  itemsToShowMobile = 1,
  itemsToShowTablet = 2,
  itemsToShowDesktop = 3,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(itemsToShowMobile);

  const updateItemsToShow = useCallback(() => {
    if (window.innerWidth < 600) {
      setItemsToShow(itemsToShowMobile);
    } else if (window.innerWidth < 900) {
      setItemsToShow(itemsToShowTablet);
    } else {
      setItemsToShow(itemsToShowDesktop);
    }
  }, [itemsToShowMobile, itemsToShowTablet, itemsToShowDesktop]);

  useEffect(() => {
    updateItemsToShow();
    window.addEventListener('resize', updateItemsToShow);

    return () => {
      window.removeEventListener('resize', updateItemsToShow);
    };
  }, [updateItemsToShow]);

  const nextSlide = () => {
    if (currentIndex < slides.length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const slideStyle = {
    width: `${100 / itemsToShow}%`,
  };

  const trackStyle = {
    width: `${(slides.length * 100) / itemsToShow}%`,
    transform: `translateX(-${(currentIndex * 100) / slides.length}%)`,
  };

  const showMoreIndicator = slides.length > itemsToShow;

  return (
    <div className="carousel">
      <button onClick={prevSlide} className={`carousel-button left ${showButton ? 'd-block' : 'd-none'}`} disabled={currentIndex === 0}>
        &lt;
      </button>
      <div className="carousel-track-wrapper">
        <div className="carousel-slides gap-4" style={trackStyle}>
          {slides.map((slide, index) => (
            <div className="carousel-slide" style={slideStyle} key={index}>
              {slide}
            </div>
          ))}
        </div>
      </div>
      <button onClick={nextSlide} className={`carousel-button right ${showButton ? 'd-block' : 'd-none'}`} disabled={currentIndex >= slides.length - itemsToShow}>
        &gt;
      </button>
      <div className={`carousel_indicators ${showIndicator ? 'd-flex' : 'd-none'}`}>
        {showMoreIndicator && slides.map((_, index) => (
          <span key={index} className={`carousel-indicator ${currentIndex === index ? 'active' : ''}`} onClick={() => goToSlide(index)}></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
