import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import React, { useEffect, useRef } from 'react';
import './css/ChoosePlan.css';
import service from 'services';
import Button from 'components/buttons/Button';
import { useNavigate } from 'react-router-dom';

const ChoosePlan = () => {
	const top = useRef(undefined);
	const navigate = useNavigate()

	useEffect(() => service.goTo(top), []);

	const breadCrumbProp = [
		{
			title: 'Home',
			link: '',
			previous: true,
		},
		{
			title: 'Applied Jobs',
			link: '',
			previous: true,
		},
		{
			title: 'Applicants',
			link: '',
			previous: true,
		},
		{
			title: 'View Applicants',
			link: '',
			previous: true,
		},
		{
			title: 'Hire',
			link: '',
		},
	];

	const star = (color) => {
		return (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_d_5923_19478)">
					<path
						d="M9.31628 0.564844L10.3678 2.96328C10.4074 3.05355 10.4705 3.13154 10.5504 3.18912C10.6304 3.2467 10.7244 3.28175 10.8225 3.29063L13.3842 3.52031C13.6741 3.5625 13.7897 3.91797 13.5796 4.12266L11.6499 5.74375C11.4936 5.875 11.4225 6.08125 11.4655 6.28047L12.0264 8.90625C12.0757 9.19453 11.7733 9.41484 11.5139 9.27813L9.278 7.96875C9.19367 7.91925 9.09766 7.89316 8.99987 7.89316C8.90209 7.89316 8.80608 7.91925 8.72175 7.96875L6.48581 9.27734C6.22722 9.41328 5.92409 9.19375 5.97331 8.90547L6.53425 6.27969C6.57644 6.08047 6.50612 5.87422 6.34987 5.74297L4.41941 4.12344C4.21003 3.91953 4.32566 3.56328 4.61472 3.52109L7.17644 3.29141C7.27459 3.28253 7.36855 3.24748 7.44853 3.1899C7.52851 3.13232 7.59157 3.05433 7.63112 2.96406L8.68269 0.565625C8.81316 0.303125 9.18659 0.303125 9.31628 0.564844Z"
						fill={color}
					/>
				</g>
				<path
					d="M9.2396 3.10713L9.06147 1.33994C9.05444 1.2415 9.03413 1.07275 9.19194 1.07275C9.31694 1.07275 9.38491 1.33291 9.38491 1.33291L9.91929 2.75166C10.1208 3.2915 10.038 3.47666 9.84351 3.58604C9.62007 3.71104 9.29038 3.61338 9.2396 3.10713Z"
					fill={color}
				/>
				<path
					d="M11.4438 5.58662L12.9766 4.39053C13.0524 4.32725 13.1891 4.22646 13.0797 4.11162C12.993 4.021 12.7586 4.15146 12.7586 4.15146L11.4172 4.67568C11.0172 4.81396 10.7516 5.01865 10.7281 5.27646C10.6977 5.62021 11.0063 5.88506 11.4438 5.58662Z"
					fill={color}
				/>
				<defs>
					<filter id="filter0_d_5923_19478" x="0.3125" y="0.368652" width="17.3745" height="16.9512" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
						<feFlood flood-opacity="0" result="BackgroundImageFix" />
						<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
						<feOffset dy="4" />
						<feGaussianBlur stdDeviation="2" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5923_19478" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5923_19478" result="shape" />
					</filter>
				</defs>
			</svg>
		);
	};

	const reqularPerks = ['Report Analysis', 'Real time Communication with Talent'];
	const plusPerks = ['Talent is interviewed by  Tybitx International', 'Talent HR Partnership with Tybitx International', 'Report Analysis', 'Real time Communication with Talent'];
	const enterprisePerks = ['Talent is interviewed by  Tybitx International', 'Talent HR Partnership with Tybitx International', 'Report Analysis', 'Real time Communication with Talent'];

	return (
		<div id="Choose_Plan_Container" ref={top}>
			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="con-header mx-4 py-4">
						<BreadCrumb context={breadCrumbProp} />
					</div>
					<div className="con-body">
						<div className=" d-flex flex-column align-items-center justify-content-center">
							<h2>Choose a plan</h2>
							<p className="my-3">To hire, a plan has to be chosen to proceed </p>
						</div>

						<div className="row  justify-content-center">
							<div class="form-check col-xl-3 col-lg-4 col-sm-6 col-12">
								<label class="form-check-label" for="flexRadioDefault1">
									<div className="plans regular">
										<div className="d-flex justify-content-between ">
											<input class="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
											<span class="custom-radio"></span>
										</div>
										<div className="plans_content my-3">
											<h2>Recruit Regular</h2>
											<p>Get a tested and approved Talent verified by Frontters</p>

											<div className="my-4">
												<Button
													darkFill
													onClick={() => navigate({ search: `?section=2` })}
													text={
														<>
															<h3>Select this plan</h3>
															<i className="fa-solid fa-arrow-right"></i>
														</>
													}
												/>
											</div>
											<div className="perks">
												<h3>Perks</h3>
												<ul>
													{reqularPerks.map((perk, index) => {
														return (
															<li key={index} className="d-flex gap-3 mt-2 ">
																<div>{star('#FAFAFA')}</div>
																{perk}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</label>
							</div>
							<div class="form-check col-xl-3 col-lg-4 col-sm-6 col-12 justify-content-center">
								<label class="form-check-label" for="flexRadioDefault2">
									<div className="plans plus">
										<div className="d-flex justify-content-between align-items-center  plans_header">
											<input class="" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
											<span class="custom-radio"></span>
											<div className="d-flex  align-items-center">
												{star('#FFB700')} <h4 className="mb-2"> Recommended</h4>
											</div>

										</div>
										<div className="plans_content my-3">
											<h2>Recruit Regular</h2>
											<p>Get a tested and approved Talent verified by Frontters</p>

											<div className="my-4">
												<Button
													onClick={() => navigate({ search: `?section=2` })}
													text={
														<>
															<h3>Select this plan</h3>
															<i className="fa-solid fa-arrow-right"></i>
														</>
													}
												/>
											</div>
											<div className="perks">
												<h3>Perks</h3>
												<ul>
													{plusPerks.map((perk, index) => {
														return (
															<li key={index} className="d-flex gap-3 mt-2 ">
																<div>{star('#000000')}</div>
																{perk}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</label>
							</div>
							<div class="form-check col-xl-3 col-lg-4 col-sm-6 col-12 justify-content-center">
								<label class="form-check-label" for="flexRadioDefault3">
									<div className="plans enterprise">
										<div className="d-flex justify-content-between ">
											<input class="" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
											<span class="custom-radio"></span>
										</div>
										<div className="plans_content my-3">
											<h2>Recruit Regular</h2>
											<p>Get a tested and approved Talent verified by Frontters</p>

											<div className="my-4">
												<Button
													onClick={() => navigate({ search: `?section=2` })}
													text={
														<>
															<h3>Select this plan</h3>
															<i className="fa-solid fa-arrow-right"></i>
														</>
													}
												/>
											</div>
											<div className="perks">
												<h3>Perks</h3>
												<ul>
													{enterprisePerks.map((perk, index) => {
														return (
															<li key={index} className="d-flex gap-3 mt-2 ">
																<div>{star('#F5F5F5')}</div>
																{perk}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChoosePlan;
