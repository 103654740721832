import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import Button from 'components/buttons/Button';
import Modal from 'components/modals/Modal';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import ModalBodyA from 'views/jobs/job-details/modals/apply-application-modal/modal-a/Modal';
import ModalBodyB from 'views/jobs/job-details/modals/apply-application-modal/modal-b/Modal';
import ModalBodyC from 'views/jobs/job-details/modals/apply-application-modal/modal-c/Modal';
import Buttons from 'views/jobs/job-details/modals/buttons/Buttons';
import DeclineModal from 'views/jobs/job-details/modals/decline-application-modal/Modal';
import DeleteJobModal from 'views/jobs/job-details/modals/delete-job/Modal';
import './css/JobDetails.style.sass';
import SuccessModal from 'views/profile/talent/modals/SuccessModal';
import FeedBackModal from 'views/profile/talent/modals/FeedBackModal';

function JobDetails() {
	service.setPageTitle('Job Details');
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const { jobId } = useParams();
	const navigate = useNavigate();

	const [jobDetails, setJobDetails] = useState({});
	const [logo, setLogo] = useState(null);
	const [applyForm, setApplyForm] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isModalLoading, setModalIsLoading] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [report, setReport] = useState({
		reason: '',
		description: '',
	});

	const handleReport = (e) => {
		const { name, value } = e.target
		setReport((prev) => ({
			...prev,
			[name]: value
		}))
	}

	const userType = service.getUserType()

	const breadCrumbProp = [
		{
			title: 'Home',
			link: '/dashboard',
		},
		{
			title: 'Opportunity',
			link: '',
		},
	];

	function getJob() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			const logo = response?.data?.result?.recruiter?.company_logo?.href_link;
			setLogo(logo);
			setJobDetails(result);
		}

		function onFinal() {
			setIsLoading(false);
		}
		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.FREELANCER:
				api.freelancers.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.RECRUITER:
				api.recruiter.getJob(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			default:
				break;
		}
	}

	useEffect(getJob, [jobId]);

	function doDeleteJob() {
		function successHandler() {
			navigate('/dashboard');
		}

		api.recruiter.deleteJob(jobId).then(successHandler).catch(console.error);
	}

	function declineApplication() {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: 'application-decline-modal',
			modalBody: <DeclineModal context={jobDetails} callback={getJob} />,
		};
	}

	function deleteJob(callback) {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: `delete-job-action-modal`,
			modalBody: <DeleteJobModal callback={callback} />,
		};
	}
	const reportThisJob = () => {
		setModalIsLoading(true)
		api.talents.jobs
			.reportJob(jobId, report)
			.then((res) => {
				if (res.status === 200) {
					setShowModal(false);
					setShowSuccessModal(true);
					setReport(report)
				}
			})
			.catch((err) => console.log(err))
			.finally(() => setModalIsLoading(false))
	};

	const handleCloseSuccessModal = () => {
		setShowSuccessModal(false);
	};
	const handleCancel = () => {
		setShowModal(false);
	};

	function applyJob() {
		const props = {
			title: 'apply to company name',
			modalATarget: 'job-details-modal-a',
			modalBTarget: 'job-details-modal-b',
			modalCTarget: 'job-details-modal-c',
			modalWidth: '900px',
		};

		function append(data) {
			setApplyForm({ ...applyForm, ...data });
		}

		return [
			{
				isStatic: true,
				modalWidth: '1000px',
				modalTitle: props.title,
				modalTarget: props.modalATarget,
				modalBody: <ModalBodyA callback={append} />,
			},
			{
				isStatic: true,
				modalTitle: props.title,
				modalWidth: props.modalWidth,
				modalTarget: props.modalBTarget,
				modalBody: <ModalBodyB callback={append} />,
			},
			{
				isStatic: true,
				modalTitle: props.title,
				modalWidth: props.modalWidth,
				modalTarget: props.modalCTarget,
				modalBody: <ModalBodyC data={applyForm} callback={getJob} />,
			},
		];
	}

	return (
		<div id="Job_Details_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height container-01">
				<div className="outline-wrap">
					<BreadCrumb context={breadCrumbProp} />
					{!isLoading && jobDetails && (
						<div className="section-a mb-4">
							<div className="header d-lg-flex align-items-center justify-content-between">
								<div className="d-lg-flex align-items-center gap-4 gap-lg-3 mb-3 mb-lg-0">
									<div className="img mb-3 mb-lg-0">
										<img src={logo || '/img/avatar.png'} alt="" style={{ border: '4px solid #FFB700' }} />
									</div>

									<div className="body">
										<div style={{ display: 'flex', gap: '10px' }}>
											<h2>{jobDetails?.title}</h2>
											<img src={jobDetails?.isJobApproved ? '/img/svg/approved.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
										</div>
										<h3>{jobDetails?.company_name && service.titleCase(jobDetails?.company_name)}</h3>
										<p className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-location-dot"></i>
											{jobDetails?.location?.state && service.titleCase(jobDetails?.location?.state)}, {jobDetails?.location?.country && service.titleCase(jobDetails?.location?.country)}
										</p>
										<p className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-globe"></i>
											<Link to="">{jobDetails?.company_link}</Link>
										</p>
									</div>
								</div>
								<Buttons hasApplied={jobDetails?.hasApplied} declineModal={declineApplication} deleteModal={deleteJob} id={jobId} />
							</div>

							{/* <div className="social d-flex flex-column flex-lg-row align-items-center gap-4">
								<p>social links:</p>
								<i className="fa-brands fa-linkedin-in"></i>
								<i className="fa-brands fa-instagram"></i>
								<i className="fa-solid fa-basketball"></i>
								<i className="fa-brands fa-github"></i>
								<i className="fa-brands fa-gitlab"></i>
							</div> */}
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-b mb-5">
							<div className="header d-flex align-items-center gap-3">
								<i className="fa-solid fa-screwdriver-wrench"></i>
								<h2>Job Description</h2>
							</div>

							<div className="body">
								<p className="mb-4">{jobDetails?.description && service.transform(jobDetails?.description)}</p>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-c mb-5">
							<div className="header d-flex align-items-center gap-2 mb-3">
								<i className="fa-solid fa-dollar-sign"></i>
								<h2>Expected Pay</h2>
							</div>

							<div className="body mb-3">
								<h2>${jobDetails?.expected_pay?.toLocaleString()}</h2>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-d mb-4">
							<div className="header d-flex align-items-center gap-2 mb-3">
								<i className="fa-solid fa-dollar-sign"></i>
								<h2>Important Safety Tips</h2>
							</div>

							<div className="body mb-4">
								<ul className="mb-3">
									<li>Do not make any payment without confirming with the {service.brand.name} Customer Support Team.</li>
									<li>Do not make any payment without confirming with the {service.brand.name} Customer Support Team.</li>
								</ul>
								<div>

									{userType !== 'recruiter' && (<Button small primaryNull text={<h2>Report Opportunity</h2>} onClick={() => setShowModal(true)} />)}

									{showModal && (
										<FeedBackModal
											title="Enter Your Query Here"
											modalTitle={'REPORT OPPORTUNITY'}
											details={report}
											Subject={'Reasons for reporting'}
											subjects={[
												{ value: 'sub1', label: 'Sub 1' },
												{ value: 'sub2', label: 'Sub 2' },
												{ value: 'sub3', label: 'Sub 3' },
											]}
											placeHolderText={'Explain better'}
											handleChange={handleReport}
											handleSubmit={reportThisJob}
											onCancel={handleCancel}
											isLoading={isModalLoading}
										/>
									)}

									{showSuccessModal && <SuccessModal text="Report Sent!" handleClose={handleCloseSuccessModal} modalTitle={'Report'} modalMessage={'Thanks for informing us about this. Our team will review this problem as soon as possible'} />}
								</div>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="footer d-flex align-items-center gap-3">
							<Buttons hasApplied={jobDetails?.hasApplied} declineModal={declineApplication} deleteModal={deleteJob} id={jobId} />
						</div>
					)}

					<div className="con-animated-bg section-1 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
					<div className="con-animated-bg section-2 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
					<div className="con-animated-bg section-3 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
				</div>
			</div>

			<Footer />

			<Modal {...applyJob()[0]} />
			<Modal {...applyJob()[1]} />
			<Modal {...applyJob()[2]} />
			<Modal {...declineApplication()} />
			<Modal {...deleteJob(doDeleteJob)} />
		</div>
	);
}

export default JobDetails;
