import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionB.style.sass';

function SectionB({ context, isLoading, prev, callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [description, setDescription] = useState('');
	const [skills, setSkills] = useState([]);

	function handleOnChangeDynamic(event, idx) {
		const name = event.target.name;
		const value = event.target.value;

		const data = [...skills];

		data[idx][name] = value;
		setSkills(data);

		if (skills.length === 1) {
			document.forms[0].elements[name].classList.remove('error');
		} else {
			document.forms[0].elements[name][idx].classList.remove('error');
		}
	}

	function handleOnChange(e) {
		const value = e.target.value;
		setDescription(value);
	}

	function addField() {
		const newField = { name: '', experience_years: '', competency: '' };
		setSkills([...skills, newField]);
	}

	function removeField(index) {
		const data = skills.filter((x, y) => y !== index);
		setSkills(data);
	}

	function validateArray(object, idx) {
		for (const key in object) {
			const formElement = document.forms[0].elements;
			if (!object[key]) {
				if (skills.length === 1) {
					formElement[key]?.focus();
					formElement[key]?.scrollIntoView({ block: 'center' });
					formElement[key].classList?.add('error');
				} else {
					formElement[key][idx]?.focus();
					formElement[key][idx]?.scrollIntoView({ block: 'center' });
					formElement[key][idx]?.classList.add('error');
				}

				return true;
			}
		}
	}

	function hasErrors() {
		return skills.some(validateArray);
	}

	function onSubmit() {
		if (!hasErrors()) {
			const skillArray = skills.map((skill) => service.omit(skill, 'competency_text', '_id'));
			callback({ required_skills: skillArray, description });
		}
	}

	function getJob() {
		setDescription(context.description);
		setSkills(context.required_skills);
	}

	useEffect(getJob, [context]);

	return (
		<div id="JobFlow_SectionB_Main_Container" ref={top}>
			<div className="section">
				<div className="container-01">
					<div className="con-title">
						<h1>What do you require for this Opportunity</h1>

						<div className="d-lg-flex align-items-center gap-3">
							<p>It's great to know what is expected, wouldn't you agree</p>
							<div className="img d-none d-lg-block">
								<img src="/img/twemoji_beaming-face-with-smiling-eyes.png" alt="" />
							</div>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form>
								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>About Job</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Job Description</label>
											</div>

											<div>
												<textarea placeholder="Enter Job Description" name="description" value={description} onChange={handleOnChange}></textarea>
												<p className="con-warn align-items-center gap-3">
													<i className="fa-solid fa-circle-exclamation"></i>
													Not more than 10000 words
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="mb-5">
									<div className="con-title">
										<i className="fa-solid fa-screwdriver-wrench"></i>
										<p>Skills and Competence</p>
									</div>

									{skills.length > 0 && (
										<div className="con-heading row mb-3 d-none d-lg-flex">
											<p className="col-3">Skills</p>
											<p className="col-3">Years of Professional Experience</p>
											<p className="col-3">Competency</p>
											<p className="col-3"></p>
										</div>
									)}

									<div className="con-input">
										{skills.length > 0 &&
											skills.map((skill, idx) => (
												<div className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-between gap-lg-3" key={idx}>
													<div className="control-form">
														<label className="d-block d-lg-none">Skill</label>
														<input placeholder={`Add Skill ${idx + 1}`} name="name" onChange={(e) => handleOnChangeDynamic(e, idx)} value={skill?.name} />
													</div>

													<div className="control-form">
														<label className="d-block d-lg-none">Years of Professional Experience</label>
														<input placeholder={`1`} type={'number'} name="experience_years" onChange={(e) => handleOnChangeDynamic(e, idx)} min={1} value={skill?.experience_years} />
													</div>

													<div className="control-form">
														<label className="d-block d-lg-none">Competency</label>
														<select name="competency" onChange={(e) => handleOnChangeDynamic(e, idx)} value={skill?.competency}>
															<option value="">Select Competency</option>
															<option value={1}>Beginner</option>
															<option value={2}>Intermediate</option>
															<option value={3}>Advanced</option>
															<option value={4}>Expert</option>
														</select>
													</div>

													<div className="control-form remove-icon">
														<i className="fa-solid fa-trash-can" onClick={() => removeField(idx)}></i>
													</div>
												</div>
											))}

										<Button
											text={
												<>
													<i className="fa-solid fa-plus"></i>
													<h2>Add New Skill</h2>
												</>
											}
											onClick={addField}
											disabled={isLoading}
										/>
									</div>
								</div>

								<div className="d-lg-flex gap-3 mt-5">
									<div className="mb-4 mb-lg-0">
										<Button
											text={
												<>
													<i className="fa-solid fa-arrow-left"></i>
													<h1>Back (Your Info)</h1>
												</>
											}
											primaryNull
											onClick={prev}
											disabled={isLoading}
										/>
									</div>

									<div className="mb-4 mb-lg-0">
										<Button
											text={
												<>
													<h1>Next</h1>
													<i className="fa-solid fa-arrow-right"></i>
												</>
											}
											disabled={isLoading}
											onClick={onSubmit}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionB;
