import React, { useState } from 'react';
import style from './css/Modal.module.sass';
import Button from 'components/buttons/Button';

function Modal({ callback }) {
	const [form, setForm] = useState({
		issuer: '',
		file: null,
		date: '',
	});

	const [formErrors, setFormErrors] = useState({
		issuer: '',
		file: '',
		date: '',
	});

	const handleOnChange = (e) => {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		setForm({ ...form, [attribute]: value });
		setFormErrors({ ...formErrors, [attribute]: '' });
	};

	const handleFileInputChange = (event) => {
		const selectedFile = event.target.files[0];
		if (event.target && event.target.files && event.target.files.length > 0) {
			setForm({ ...form, file: selectedFile });
			setFormErrors({ ...formErrors, file: '' });
		}
	};

	const handleModalSubmit = () => {
		if (validateForm()) {
			callback(form);
			setForm({ issuer: '', file: null, date: '' });
			setFormErrors({ issuer: '', file: '', date: '' });
		}
	};

	const validateForm = () => {
		let isValid = true;

		if (!form.issuer) {
			setFormErrors((prevErrors) => ({ ...prevErrors, issuer: 'Issuer is required' }));
			isValid = false;
		}

		if (!form.date) {
			setFormErrors((prevErrors) => ({ ...prevErrors, date: 'Issued date is required' }));
			isValid = false;
		}

		if (!form.file) {
			setFormErrors((prevErrors) => ({ ...prevErrors, file: 'File is required' }));
			isValid = false;
		}

		return isValid;
	};

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
};

	return (
		<section className={style['cer-main']}>
			<div className="modal-header">
				<h2>Add Certificate</h2>
			</div>
			<div className="modal-body">
				<div id="form-group">
					<label htmlFor="issuer">Certificate Issuer *</label>
					<input type="text" id="issuer" name="issuer" value={form.issuer} onChange={handleOnChange} className='p-3'/>
					{formErrors.issuer && <span className="error">{formErrors.issuer}</span>}
				</div>
				<div className="form-group">
					<label htmlFor="issuer">Issued Date *</label>
					<input type="date" id="date" name="date" value={form.date} onChange={handleOnChange} className='p-3' max={getCurrentDate()}/>
					{formErrors.date && <span className="error">{formErrors.date}</span>}
				</div>
				<div className="form-group">
					<label htmlFor="file">Certificate File *</label>
					<input type="file" id="file" name="file" onChange={handleFileInputChange} />
					{formErrors.file && <span className="error">{formErrors.file}</span>}
				</div>
			</div>
			<div className="modal-footer">
				<Button
					text={
						<>
							<h2>Add Certificate</h2>
						</>
					}
					{...exitModal()}
					onClick={handleModalSubmit}
				/>
			</div>
		</section>
	);
}

export default Modal;
