import { useEffect, useRef } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/Onboarding.style.sass';
import { navbarComp } from './navbar-components/navbar-comp';
import Recruiter from './recruiter/Recruiter';
import Talent from './talent/Talent';

function Onboarding() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const userType = service.getUserType();

	service.setPageTitle(`${service.titleCase(userType)} Onboarding`);

	return (
		<div id="Onboarding_Main_Component" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				{userType === service.RECRUITER && <Recruiter />}
				{userType === service.TALENT && <Talent />}
			</div>

			<Footer />
		</div>
	);
}

export default Onboarding;
