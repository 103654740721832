import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import Modal from 'components/modals/Modal';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import './css/SectionD.style.sass';
import AddEducationModalBody from './modals/add-education/Modal';
import AddExperienceModalBody from './modals/add-experience/Modal';
import DeleteExperienceModalBody from './modals/delete-experience/Modal';
import DeleteEducationModalBody from './modals/delete-experience/Modal';

function SectionD({ context }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const currencies = [
		{ name: "Nigerian Naira", symbol: "₦", code: "NGN" },
		{ name: "United States Dollar", symbol: "$", code: "USD" },
		{ name: "British Pound Sterling", symbol: "£", code: "GBP" },
		{ name: "Euro", symbol: "€", code: "EUR" },
		{ name: "Japanese Yen", symbol: "¥", code: "JPY" },
		{ name: "Swiss Franc", symbol: "CHF", code: "CHF" },
		{ name: "Canadian Dollar", symbol: "C$", code: "CAD" },
		{ name: "Australian Dollar", symbol: "A$", code: "AUD" },
		{ name: "Indian Rupee", symbol: "₹", code: "INR" },
		{ name: "Chinese Yuan", symbol: "¥", code: "CNY" },
		{ name: "South African Rand", symbol: "R", code: "ZAR" },
		{ name: "United Arab Emirates Dirham", symbol: "د.إ", code: "AED" },
		{ name: "Singapore Dollar", symbol: "S$", code: "SGD" },
		{ name: "Mexican Peso", symbol: "Mex$", code: "MXN" },
		{ name: "Brazilian Real", symbol: "R$", code: "BRL" },
		{ name: "Thai Baht", symbol: "฿", code: "THB" },
		{ name: "Hong Kong Dollar", symbol: "HK$", code: "HKD" },
		{ name: "New Zealand Dollar", symbol: "NZ$", code: "NZD" },
		{ name: "Swedish Krona", symbol: "kr", code: "SEK" },
		{ name: "Norwegian Krone", symbol: "kr", code: "NOK" },
		{ name: "Danish Krone", symbol: "kr", code: "DKK" },
		{ name: "Russian Ruble", symbol: "₽", code: "RUB" },
		// Add more currencies as needed
	];
	const [data, setData] = useState({
		onsite_experience: '',
		remote_experience: '',
		current_salary: '',
		expected_salary: '',
		current_salary_currency: '',
		expected_salary_currency: '',
		isOnboardingComplete: false,
		experience: [],
		education: [],
		skills: '',
		file_name: '',
		uploadDate: '',
	});
	const [skills, setSkills] = useState([{ name: '', experience_years: '', competency: '' }]);

	const [isLoading, setIsLoading] = useState(false);

	function getData() {
		setData({ ...data, ...context });
	}

	// eslint-disable-next-line
	useEffect(getData, [context]);

	function deleteExperienceModal(idx) {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: `delete-experience-action-modal-${idx}`,
			modalBody: <DeleteExperienceModalBody context={idx} callback={doDeleteExperience} />,
		};
	}

	function addExperienceModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-experience-action-modal`,
			modalTitle: 'add work experience',
			modalBody: <AddExperienceModalBody callback={doAddExperience} />,
		};
	}

	function deleteEducationModal(idx) {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: `delete-education-action-modal-${idx}`,
			modalBody: <DeleteEducationModalBody context={idx} callback={doDeleteEducation} />,
		};
	}

	function addEducationModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-education-action-modal`,
			modalTitle: 'education',
			modalBody: <AddEducationModalBody callback={doAddEducation} />,
		};
	}

	function doAddExperience(body) {
		setData({ ...data, experience: [...data.experience, body] });
	}

	function doAddEducation(body) {
		setData({ ...data, education: [...data.education, body] });
	}

	function doDeleteExperience(idx) {
		setData({ ...data, experience: data?.experience.filter((x, y) => y !== idx) });
	}

	function doDeleteEducation(idx) {
		setData({ ...data, education: data?.education.filter((x, y) => y !== idx) });
	}

	const [form, setForm] = useState({
		onsite_experience: '',
		remote_experience: '',
		current_salary: '',
		expected_salary: '',
		current_salary_currency: '',
		expected_salary_currency: '',
	});

	const [formErrors, setFormErrors] = useState(form);

	function handleOnChange(e) {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		console.log(value)
		setForm({ ...form, [`${attribute}`]: value });
		setFormErrors({ ...formErrors, [`${attribute}`]: `` });
	}

	function handleOnChangeDynamic(event, idx) {
		const name = event.target.name;
		const value = event.target.value;

		const data = [...skills];

		data[idx][name] = value;
		setSkills(data);

		if (skills.length === 1) {
			document.forms[0].elements[name].classList.remove('error');
		} else {
			document.forms[0].elements[name][idx].classList.remove('error');
		}
	}

	function addField() {
		const newField = { name: '', experience_years: '', competency: '' };
		setSkills([...skills, newField]);
	}

	function removeField(index) {
		const data = skills.filter((x, y) => y !== index);
		setSkills(data);
	}

	function validateArray(object, idx) {
		for (const key in object) {
			const formElement = document.forms[0].elements;
			if (!object[key]) {
				if (skills.length === 1) {
					formElement[key]?.focus();
					formElement[key]?.scrollIntoView({ block: 'center' });
					formElement[key].classList?.add('error');
				} else {
					formElement[key][idx]?.focus();
					formElement[key][idx]?.scrollIntoView({ block: 'center' });
					formElement[key][idx]?.classList.add('error');
				}

				return true;
			}
		}
	}

	function hasErrors() {
		for (const field in form) {
			if (!form[field]) {

				if (field.includes('currency')) {
					validationHandler(field, ' Currency is requried')

				} else {
					validationHandler(field);

					console.warn(`{${field}} field is required`);
				}

				return true;
			} else if (validations()) {
				return true;
			} else if (skills.some(validateArray)) {
				return true;
			}
		}

		return false;
	}

	function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.forms[0].elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	}

	function validations() {
		return false;
	}

	function configFields() {
		data.onsite_experience = form.onsite_experience;
		data.remote_experience = form.remote_experience;
		data.current_salary = form.current_salary;
		data.expected_salary = form.expected_salary;
		data.current_salary_currency = form.expected_salary
		data.expected_salary_currency = form.expected_salary_currency;
		data.current_salary_currency=form.current_salary_currency
		data.skills = skills;
		data.isOnboardingComplete = true;

		delete data?.firstName;
		delete data?.lastName;
		delete data?.email;
		delete data?.file_name;
		delete data?.uploadDate;
		delete data?.filename;
	}

	function doSubmit() {
		if (!hasErrors()) {
			setIsLoading(true);
			configFields();

			function successHandler() {
				navigate({ search: `?section=5` });
			}

			function errorHandler(response) {
				console.error(response);
			}

			function onFinal() {
				setIsLoading(false);
			}

			api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(onFinal);
		}
	}

	return (
		<div id="Onboarding_Talent_SectionD_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<p className="mb-3 d-flex align-items-center gap-3">Basic Info</p>

							<h2 className="display">Let's get to know you better!</h2>

							<p className="mb-3">Let's dig a little deeper!</p>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="75" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01">
				<div className="con-form">
					<div className="form">
						<form>
							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/bar.svg" alt="box" className='svg' />

									<h2>What are your experiences</h2>
								</div>

								{data?.experience?.length > 0 &&
									data?.experience.map((x, y) => (
										<div key={y}>
											<div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4">
												<div className="experience-block mb-4 mb-lg-0">
													<div className="one d-flex align-items-center gap-2">
														<h2>{x?.job_title.split('[')[0].trim() || `job title`}</h2>

														<div className="tip">{x.job_title?.split('[')[1]?.split(']')[0].toLowerCase() || ``}</div>
													</div>
													<p className="two">{x.company || `company`}</p>
													<p className="three">
														{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? (
															'Present'
														) : (
															<>
																{service.monthNames()[new Date(x.end_date).getMonth()]}&nbsp;
																{new Date(x.end_date).getFullYear()}
															</>
														)}
													</p>
												</div>

												<div>
													<Button
														small
														primaryNull
														text={
															<>
																<i className="fa-solid fa-trash"></i>
																Delete
															</>
														}
														{...deleteExperienceModal(y)}
													/>
												</div>
											</div>

											<Modal {...deleteExperienceModal(y)} />
										</div>
									))}

								<div className="mt-4">
									<Button
										text={
											<>
												<img src="/img/svg/boxwhite.svg" alt="box" className='svg' />
												Add Work Experience
											</>
										}
										{...addExperienceModal()}
									/>
								</div>
							</div>

							<div className="my-5">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/bookopen.svg" alt="book" className='svg' />

									<h2>Education</h2>
								</div>

								{data?.education?.length > 0 &&
									data?.education.map((x, y) => (
										<div key={y}>
											<div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4">
												<div className="experience-block">
													<div className="one d-flex align-items-center gap-2">
														<h2>{x?.school}</h2>
													</div>
													<p className="two">{x?.degree}</p>
													<p className="three">
														{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? 'Present' : <>{x.end_date ? new Date(x.end_date).getFullYear() : `Date`}</>}
													</p>
												</div>

												<div>
													<Button
														small
														primaryNull
														text={
															<>
																<i className="fa-solid fa-trash"></i>
																Delete
															</>
														}
														{...deleteEducationModal(y)}
													/>
												</div>
											</div>

											<Modal {...deleteEducationModal(y)} />
										</div>
									))}

								<div className="mt-4">
									<Button
										text={
											<>
												<i className="fa-regular fa-square"></i>
												Add Educational Background
											</>
										}
										{...addEducationModal()}
									/>
								</div>
							</div>

							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/officebox.svg" alt="book" className='svg' />

									<h2>How many years of experience do you have?</h2>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-lg-flex justify-content-between">
											<label>Years of On-site Experience</label>
										</div>

										<input type="number" placeholder="Enter years of experience" name="onsite_experience" value={form.onsite_experience} onChange={handleOnChange} className={formErrors.onsite_experience ? 'error' : ''} min={1} />
										<label className="error-msg mb-3">{formErrors.onsite_experience}</label>
									</div>

									<div className="control-form">
										<div className="d-lg-flex justify-content-between">
											<label>Years of Remote Experience</label>
										</div>

										<input type="number" placeholder="Enter years of experience" name="remote_experience" value={form.remote_experience} onChange={handleOnChange} className={formErrors.remote_experience ? 'error' : ''} min={1} />
										<label className="error-msg mb-3">{formErrors.remote_experience}</label>
									</div>
								</div>
							</div>

							<div className="con-skills mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/toolkit.svg" alt="book" className='svg' />

									<h2>Skills and Competence</h2>
								</div>

								{skills.length > 0 && (
									<div className="con-header row mb-3 d-none d-lg-flex">
										<h2 className="col-3">Skills</h2>
										<h2 className="col-3">Years of Professional Experience</h2>
										<h2 className="col-3">Competency</h2>
										<i className="col-3"></i>
									</div>
								)}

								<div className="con-input">
									{skills.length > 0 &&
										skills.map((skill, idx) => (
											<div className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-between gap-lg-3" key={idx}>
												<div className="control-form">
													<label className="d-block d-lg-none">Skill</label>
													<input placeholder={`Add Skill ${idx + 1}`} name="name" onChange={(e) => handleOnChangeDynamic(e, idx)} value={skill?.name} />
												</div>

												<div className="control-form">
													<label className="d-block d-lg-none">Years of Professional Experience</label>
													<input placeholder={'Years of Experience'} type={'number'} name="experience_years" onChange={(e) => handleOnChangeDynamic(e, idx)} min={1} value={skill?.experience_years} />
												</div>

												<div className="control-form">
													<label className="d-block d-lg-none">Competency</label>
													<select name="competency" onChange={(e) => handleOnChangeDynamic(e, idx)} value={skill?.competency}>
														<option value="">Select Competency</option>
														<option value={1}>Beginner</option>
														<option value={2}>Intermediate</option>
														<option value={3}>Advanced</option>
														<option value={4}>Expert</option>
													</select>
												</div>

												<div className="control-form remove-icon">
													<i className="fa-solid fa-trash-can" onClick={() => removeField(idx)}></i>
												</div>
											</div>
										))}

									<Button
										text={
											<>
												<i className="fa-solid fa-plus"></i>
												<h2>Add New Skill</h2>
											</>
										}
										onClick={addField}
										disabled={isLoading}
									/>
								</div>
							</div>
							<div className="d-flex gap-4 flex-lg-nowrap">

								<div className="control-form">


									<div className="d-lg-flex justify-content-between gap-4">
										<div className="w-100">
											{/* <input type="number" placeholder="Annual Salary ($)" name="current_salary" value={form.current_salary} onChange={handleOnChange} className={formErrors.current_salary ? 'error' : 'form-control'} min={1} /> */}
											<div className="d-lg-flex justify-content-between flex-column" style={{ flex: 1 }}>
												<div className="con-title d-flex align-items-center gap-3 mb-3">
													{/* <img src="/img/svg/dollar2.svg" alt="payment before" className='svg' style={{ width: '11px', height: '15px' }} /> */}
													<h2 style={{ fontSize: '20px' }}>What is your current annual salary  ?</h2>
												</div>

												<label className="error-msg mb-3 col-lg-6">{formErrors.current_salary}</label>
											</div>

											<div className="input-group mb-3">
												<select className={formErrors.current_salary_currency ? 'error form-control' : 'form-control'} style={{ flex: 3 }} aria-label="Select input" name='current_salary_currency' onChange={handleOnChange} value={form.current_salary_currency}  >
													<option defaultValue>Select  Currency</option>

													{
														currencies.map((currency) => {
															return <option value={currency.code} key={currency.code} className='my-3 mx-2' >{currency.name}</option>

														})
													}

												</select>
												<input type="number" placeholder="Annual Salary " name="current_salary" className={formErrors.current_salary ? 'error form-control' : 'form-control'} value={form.current_salary} onChange={handleOnChange} min={1} style={{ flex: '7 7 auto' }} />

											</div>
											<label className='error-msg mb-3'> {formErrors.current_salary_currency}</label>


										</div>

									</div>
								</div>
								<div className="control-form">


									<div className="d-lg-flex justify-content-between gap-4">

										<div className="w-100">

											<div className="d-flex justify-content-between flex-column" style={{ flex: 1 }}>
												<div className="con-title d-flex align-items-center gap-3 mb-3">
													{/* <img src="/img/svg/dollar2.svg" alt="payment before" className='svg' style={{ width: '11px', height: '15px' }} /> */}
													<h2 style={{ fontSize: '20px' }}>What is your expected annual salary </h2>
												</div>

												<label className="error-msg mb-3 col-lg-6">{formErrors.expected_salary}</label>
											</div>

											<div className="input-group mb-3">
												<select className={formErrors.expected_salary_currency ? 'form-select error' : 'form-select'} style={{ flex: 3 }} aria-label="Select input" name='expected_salary_currency' onChange={handleOnChange} value={form.expected_salary_currency}  >
													<option defaultValue value={''}>Select Currency</option>

													{
														currencies.map((currency, index) => {

															return <option value={currency.code} key={index} className='my-3 mx-2' > {currency.name}</option>

														})
													}

												</select>

												<input type="number" placeholder="Annual Salary " name="expected_salary" className={formErrors.expected_salary ? 'error form-control' : 'form-control'} value={form.expected_salary} onChange={handleOnChange} min={1} style={{ flex: '7 7 auto' }} />

											</div>
											<div>
												<label className='error-msg mb-3'> {formErrors.expected_salary_currency}</label>

											</div>


										</div>
									</div>
								</div>

							</div>





							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>One Step back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={doSubmit} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* Modals */}
			<Modal {...addExperienceModal()} />
			<Modal {...addEducationModal()} />
		</div>
	);
}

export default SectionD;
