import Button from 'components/buttons/Button';
// import MessageForm from 'components/forms/message-form/MessageForm';
// import LoaderButton from 'components/loader/loader-btn/LoaderButton.jsx';
// import UploadViewer from 'components/upload-viewer/UploadViewer';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
// import classNames from 'utils/conditional-css-classes/classNames';
// import Previous from '../previous/Previous';
import './css/SectionA.style.sass';
import ProgressBar from 'components/progress-bar/ProgressBar';
import './css/SectionA.css';

function SectionA({ callback, isLoading, errorMsg, setErrorMsg }) {
	// const [file, setFile] = useState(null);
	// const [largeFileError, setLargeFileError] = useState(false);
	// const [noFileError, setNoFileError] = useState(false);
	const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);


	// const maxSize = 4194304;

	// function handleFileChange({ target: { files } }) {
	// 	if (files) {
	// 		setNoFileError(false);
	// 		setErrorMsg('');

	// 		if (files[0]?.size < maxSize) {
	// 			setFile(files[0]);
	// 			setLargeFileError(false);
	// 		} else {
	// 			setFile(null);
	// 			setLargeFileError(true);
	// 		}
	// 	}
	// }

	// function doSubmit() {
	// 	if (file) {
	// 		callback(file);
	// 	} else {
	// 		setNoFileError(true);
	// 		setErrorMsg('Resume is required');
	// 	}
	// }
	const [optionSelected, setOptionSelected] = useState('');
	const [errorText, setErrorText] = useState(false);

	function navigateToNextSection() {
		if (optionSelected === '') {
			setErrorText(true);

			setTimeout(() => {
				setErrorText(false);
			}, 3000);
		} else if (optionSelected === 'diy') {
			navigate({ search: `?section=3` });
		} else {
			return
		}
	}

	return (
		<div id="Onboarding_Talent_SectionA_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="my-5 row align-items-center justify-content-between py-5">
						<div className="col-lg-5">
							<ProgressBar percent="40" />
						</div>
						<div className="col-lg-2 d-flex align-items-center gap-3">
							<div className="onboarding_time ">
								<img src="/time.svg" alt="" />
							</div>
							<p>5-10 minutes</p>
						</div>
					</div>
					<div className="header col-lg-8">
						{/* <div className="previous mb-3">
							<Previous />
						</div> */}

						<h2 className="display">How would you like to introduce yourself to us?</h2>
						<p className="my-3 text_intro">
							We'd love to discover your educational journey and abilities! It saves you time to import your details—you can tweak them before your profile goes live.
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>

			<div className="con-form container-01 select_method_con">
				{/* <div className="form">
					<div className="con-heading">
						<h2 className="mb-5">Upload your Resume here</h2>
						<p>Worrying if it is out of date? Relax, we will help you add some nice touches later on.</p>
					</div>

					<div className="form-element">
						<div className="con-upload">
							<label className={`uploads ${classNames(noFileError && `upload-error`)}`}>
								<div className="img">
									<img src="img/Vector (2).png" alt="" />
								</div>
								<input type="file" accept={`application/pdf,application/msword`} onChange={handleFileChange} />
							</label>
						</div>

						<div className="d-lg-flex align-items-center justify-content-between mb-4">
							<h2 className={`warn ${classNames(largeFileError && `error-msg`)}`}>Max. file size: 4MB (.pdf or .doc files only)</h2>

							{file && (
								<div className="mt-4 mt-lg-0">
									<UploadViewer filename={file?.name} callback={() => setFile()} />
								</div>
							)}
						</div>

						{errorMsg && <MessageForm message={errorMsg} />}

						<div className="my-5">
							<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={doSubmit} disabled={isLoading} />
						</div>

						<div className="footer d-flex gap-5">
							<p>Don't have one?</p>
							<p onClick={() => navigate({ search: `?section=3` })} style={{ color: '#CC5600' }}>
								Skip this step
							</p>
						</div>
					</div>
				</div> */}
				<div className="row">
					<div className="d-flex flex-column align-items-center gap-3  position-relative col-sm-3 select_box ">
						<input type="radio" id="talent" name="radio-group" class="radio-input" value="talent" onChange={() => setOptionSelected('linkedin')} disabled={true} />
						<label htmlFor="talent" class="radio-label w-100">
							<span class="radio-inner-circle"></span>

							<div className="main-selector mb-3 mb-lg-0">
								<div className="heading">
									<h2>Talent</h2>
								</div>

								<div className="body">
									<div className="img">
										<img src="/img/svg/linkinfile2.svg" alt="" style={{opacity:0.5}} />
									</div>
								</div>
							</div>
						</label>

						<div className="cta w-100">
							<p>Import from Linkedin</p>
						</div>
					</div>

					<div className="d-flex flex-column align-items-center gap-3  position-relative col-sm-3 select_box ">
						<input type="radio" id="diy" name="radio-group" class="radio-input" value="diy" onChange={() => setOptionSelected('diy')}  />
						<label htmlFor="diy" class="radio-label w-100"  style={{cursor:'pointer'}}>
							<span class="radio-inner-circle"></span>

							<div className="main-selector mb-3 mb-lg-0">
								<div className="heading">
									<h2>diy</h2>
								</div>

								<div className="body">
									<div className="img">
										<img src="/img/svg/diy.svg" alt="" />
									</div>
								</div>
							</div>
						</label>

						<div className="cta w-100">
							<p>Do it yourself (15 mins)</p>
						</div>
					</div>
				</div>

				<div className="my-3  d-flex  align-items-center gap-5">
					<Button
						text={
							<>
								<h1>Next</h1>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						onClick={navigateToNextSection}
					/>
					<p className={`error-msg ${errorText ? 'd-block' : 'd-none'}`}>Please select one of the options</p>
				</div>
			</div>
		</div>
	);
}

export default SectionA;
