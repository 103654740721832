import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SectionA from './section-a/SectionA';
// import SectionB from './section-b/SectionB';
import SectionC from './section-c/SectionC';
import SectionD from './section-d/SectionD';
import SectionE from './section-e/SectionE';
import { auth } from 'services/api/auth.js';
// import SectionF from './section-f/SectionF';

function Talent() {
	const [query] = useSearchParams();
	const navigate = useNavigate();
	const [cvFileData, setCvFileData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	function doSubmitA(data) {
		const formData = new FormData();

		function successResponse(response) {
			const result = response.data.result;
			result.filename = data.name;

			setIsLoading(false);
			setCvFileData(result);
			navigate({ search: `?&section=2` });
		}

		function errorHandler(response) {
			const result = response.data.result;
			setIsLoading(false);
			setErrorMsg(result);
		}

		if (data) {
			setErrorMsg('');
			setIsLoading(true);
			formData.append('file', data);

			auth.parseCv(formData).then(successResponse).catch(errorHandler);
		}
	}

	const doSubmitE = (data) => {
		navigate('/dashboard');
		console.log(data);
	};

	return (
		<div id="Onboarding_Talent_Main_Component">
			{query.get('section') === '1' && <SectionA callback={doSubmitA} isLoading={isLoading} errorMsg={errorMsg} setErrorMsg={setErrorMsg} />}

			{/* {query.get('section') === '2' && <SectionB context={cvFileData} />} */}
			{/* {query.get('section') === '12' && <SectionF context={cvFileData} />} */}

			{query.get('section') === '3' && <SectionC context={cvFileData} />}

			{query.get('section') === '4' && <SectionD context={cvFileData} />}

			{query.get('section') === '5' && <SectionE callback={doSubmitE} />}
		</div>
	);
}

export default Talent;
