import BreadCrumb from 'components/bread-crumb/BreadCrumb.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp.jsx';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination.jsx';
import { Link, useNavigate } from 'react-router-dom';
import './css/RecommendedJobs.style.sass';
import Button from 'components/buttons/Button';

function RecommendedJobs() {
	service.setPageTitle('Recommended Jobs');
	const userType = service.getUserType();
	const navigate = useNavigate()

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [jobs, setRecommended] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [message, setMessage] = useState([]);

	function getJobs() {
		function successHandler(response) {
			const result = response?.data?.result;
			setRecommended(result.filter((job) => job?.status === 'published' && job?.isJobApproved !== false));

		}

		function onFinal() {
			setIsLoading(false);
		}
		if (userType === 'talent') {
			api.talents.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
		} else {
			api.freelancers.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
		}
	}

	function toggleSave(id) {
		function successHandler() {
			setMessage([...message, { text: 'Job saved successfully' }]);
			clearMessages();
		}
		function errorHandler(response) {
			const result = response.data.result;
			setMessage([...message, { text: result }]);
			clearMessages();
		}
		if (userType === 'talent'){
			api.talents.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);

		}else{
			api.freelancers.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);

		}
	}
	function clearMessages() {
		function clear() {
			setMessage([]);
		}

		setTimeout(clear, service.errorMilliSecond);
	}

	useEffect(getJobs, [userType]);

	const breadCrumbProp = [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'Recommended Jobs',
			link: '',
		},
	];

	return (
		<div id="Recommended_Jobs_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap">
						<div className="con-header mx-4 mt-4">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Recommended Jobs</h2>

							<div className="body">
								{!isLoading &&
									(jobs?.length > 0 && (
										jobs?.map((job, idx) => (
											<div className="card-job" key={idx} style={{width:'100%'}}>
												<div className="header d-flex align-items-center justify-content-between">
													<div className="d-flex align-items-center">
														{[1, 1, 1].map((_, y) => (
															<div className={`box box-${y + 1}`} key={y}></div>
														))}
													</div>

													<i className="fa-solid fa-xmark"></i>
												</div>

												<div className="context d-flex align-items-start gap-3">
													<div className="con-class d-flex justify-content-between" style={{width:'100%'}}>
														<div className="class-1" style={{width:'90%'}}>
															<h2 className="d-flex align-items-center gap-3">
																{job.title} <i>{job.job_type}</i>
															</h2>
															<h3 className="mt-2 mb-3" title={job?.company_name}>
																{job?.company_name ? service.truncateText(job?.company_name) : `company name`}
															</h3>
															<h4 className="mb-3">
																{job.location.state}, {job.location.country}
															</h4>
															<p>{job?.currency}{job.expected_pay.toLocaleString()}/yr</p>
														</div>

														<div className="class-2 d-flex flex-column align-items-end justify-content-between" style={{width:'10%'}}>
															<i className="fa-regular fa-bookmark" onClick={() => toggleSave(job?._id)}></i>

															<Link to={`/dashboard/jobs/${job?._id}`} className="arrow-btn outline">
																<i className="fa-solid fa-arrow-right"></i>
															</Link>
														</div>
													</div>
												</div>
											</div>
										))
									) )}
							</div>
							{!isLoading && jobs?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src={'/img/svg/noexp.svg'} alt="no experience" style={{ width: '70px' }} />
									<h5 className="fw-bold">No Recommended jobs yet</h5>
									<p className="fw-normal">Go to your profile and set your Designation to see jobs</p>
									<Button primaryNull text={'Bookmark A Job'} onClick={() => navigate('/dashboard/jobs/recommended-jobs')} />
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>

						{jobs?.length > 9 && (
							<div className="footer">
								<IndexPagination />
							</div>
						)}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default RecommendedJobs;
