import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import './css/JobDetails.style.sass';
import '../../hire/section-4/css/SectionC.style.sass'

function JobCriteria() {
	service.setPageTitle('Job Criteria');
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const { jobId } = useParams();
	const navigate = useNavigate();

	const [jobDetails, setJobDetails] = useState({});
	const [logo, setLogo] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	function getJob() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			const logo = response?.data?.result?.recruiter?.company_logo?.href_link;
			setLogo(logo);
			setJobDetails(result);
		}

		function onFinal() {
			setIsLoading(false);
		}
		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.FREELANCER:
				api.freelancers.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.RECRUITER:
				api.recruiter.getJob(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			default:
				break;
		}
	}

	useEffect(getJob, [jobId]);


	return (
		<div id="Job_Details_Main_Container" ref={top} style={{ backgroundColor: '#FFFBF2', paddingBottom: '30px' }}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height container-01">

			<div className="con-header" style={{marginBottom:'15px'}}>
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<div className="mb-3 d-flex align-items-center gap-1" style={{ color: '#CC5600', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
								<i class="fa-solid fa-chevron-left"></i>
								<p>Back</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h1 style={{fontWeight:'bolder', paddingBottom:'40px'}}>Job Criteria</h1>
			
				<div className="outline-wrap">
					{!isLoading && jobDetails && (
						<div className="section-a mb-4">
							<div className="header d-lg-flex align-items-center justify-content-between">
								<div className="d-lg-flex align-items-center gap-4 gap-lg-3 mb-3 mb-lg-0">
									<div className="img mb-3 mb-lg-0">
										<img src={logo || '/img/avatar.png'} alt="" style={{ border: '4px solid #FFB700' }} />
									</div>

									<div className="body">
										<div style={{ display: 'flex', gap: '10px' }}>
											<h2>{jobDetails?.title}</h2>
											<img src={jobDetails?.isJobApproved ? '/img/svg/approved.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
										</div>
										<h3>{jobDetails?.company_name && service.titleCase(jobDetails?.company_name)}</h3>
										<p className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-location-dot"></i>
											{jobDetails?.location?.state && service.titleCase(jobDetails?.location?.state)}, {jobDetails?.location?.country && service.titleCase(jobDetails?.location?.country)}
										</p>
										<p className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-globe"></i>
											<Link to="">{jobDetails?.company_link}</Link>
										</p>
									</div>
								</div>
							</div>

							{/* <div className="social d-flex flex-column flex-lg-row align-items-center gap-4">
								<p>social links:</p>
								<i className="fa-brands fa-linkedin-in"></i>
								<i className="fa-brands fa-instagram"></i>
								<i className="fa-solid fa-basketball"></i>
								<i className="fa-brands fa-github"></i>
								<i className="fa-brands fa-gitlab"></i>
							</div> */}
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-b mb-5">
							<div className="header d-flex align-items-center gap-3">
								<i className="fa-solid fa-screwdriver-wrench"></i>
								<h2>Job Description</h2>
							</div>

							<div className="body">
								<p className="mb-4">{jobDetails?.description && service.transform(jobDetails?.description)}</p>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-c mb-5">
							<div className="header d-flex align-items-center gap-2 mb-3">
								<i className="fa-solid fa-dollar-sign"></i>
								<h2>Expected Pay</h2>
							</div>

							<div className="body mb-3">
								<h2>${jobDetails?.expected_pay?.toLocaleString()}</h2>
							</div>
						</div>
					)}
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default JobCriteria;
