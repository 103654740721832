import Button from 'components/buttons/Button';
import LoginForm from 'components/forms/login-form/LoginForm';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
import { auth } from 'services/api/auth.js';
import Auth from 'utils/commons/auth/Auth';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import './css/Login.style.sass';

function Form({ callback, ...props }) {

	



	return (
		<div className="col-12 col-lg-5">
			<div className="con-form">
				<div className="form">
					<div className="title">
						<h2>Sign in to your account</h2>

						<div className="d-flex gap-2 mt-4 mb-4">
							<p>or</p>
							<Link to="/register">Create a new account</Link>
						</div>
					</div>

					<LoginForm onSubmit={callback} {...props} />
				</div>
			</div>
		</div>
	);
}

function Login({ isLogin }) {
	service.setPageTitle('Sign in to your account');

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const authChecker = () => {
		if (service.getTokensLocalStorage() === null) isLogin(false);
	};

	useEffect(authChecker);

	function handleLogin(data) {
		setIsLoading(true);

		function successHandler(response) {
			const {
				token,
				result: { isOnboardingComplete },
			} = response?.data;

			isLogin(true);
			setIsLoading(false);
			service.setTokensLocalStorage(token);
			const userType = service.getUserType();

			if (userType === 'freelancer' || isOnboardingComplete) {
				navigate('/dashboard');
				service.reloadPage();
			} else if (!isOnboardingComplete) {
				navigate(`/onboarding?${service.section}1`);
				service.reloadPage();
			}
		}

		function errorHandler(err, level, data) {
			if (level === 1) {
				apiCall('freelancer', 2);
			} else if (level === 2) {
				apiCall('recruiter', 0);
			} else {
				console.error(err);
				setIsLoading(false);

				if (err?.data?.status === 403) {
					const url = err.config.url.split('/');
					const userType = url[url.length - 2];

					setErrorMsg({
						error: err.data.result,
						url: {
							text: 'verify here',
							link: `/verify-account?recipient=${btoa([data?.email, userType])}`,
						},
					});
				} else {
					setErrorMsg(err.data.result);
				}
			}
		}

		function apiCall(userType, errorLevel) {
			auth
				.login(data, userType)
				.then(successHandler)
				.catch((err) => errorHandler(err, errorLevel, data));
		}

		apiCall('talent', 1);
	}

	return (
		<div id="Login_Main_Container" ref={top}>
			<Navbar
				rightContent={
					<div className="d-flex align-items-center justify-content-between">
						<Button small emptyFill text="Home" redirect="/" />
						<Button small primaryEmptyFill text="Sign up" redirect="/register" />
						<Button small text="Login" />
					</div>
				}
				sidebarContent={
					<>
						<ul id="con-navbar-sidebar">
							<Link to="/register" className="active">
								<li>Register</li>
							</Link>
							<Link to="/login" className="active">
								<li>Login</li>
							</Link>
						</ul>
					</>
				}
			/>

			<div className="con-margin-height">
				<Auth context={<Form {...{ loadingStatus: isLoading, callback: handleLogin, errorMsg: errorMsg, errorCallback: setErrorMsg }} />} />
			</div>
		</div>
	);
}

export default Login;
