import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import service from 'services';
// import api from 'services/api';
import './css/SectionC.style.sass';
import '../../jobs/applicants/css/ApplicantsCards.scss';
import api from 'services/api';

function SectionA({ hire, setHire, setPicked}) {
	const { talentId, jobId } = useParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [applicants, setApplicants] = useState([]);

	function fetchApplicants() {
		setIsLoading(true);

		function onSuccess(response) {
			const result = response.data?.result || [];

			const selectedTalents = result.filter((talent) => talent.talent.id === talentId);
			
			setApplicants(result);
			setHire((prev) => {
				const updatedSelectedTalents = new Set([...prev.selectedTalents, ...selectedTalents.map((talent) => talent.talent.id)]);
				const updatedSelectedTalentType = selectedTalents[0].talent?.full_time_decision
				return {
					...prev,
					talentType:updatedSelectedTalentType,
					selectedTalents: Array.from(updatedSelectedTalents),
				};
			});
			setPicked(selectedTalents);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter
			.getJobApplicants(jobId)
			.then(onSuccess)
			.catch((res) => console.log(res))
			.finally(onFinal);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchApplicants, []);

	function handleSelectTalent(id) {
		setHire((prev) => {
			const isSelected = prev.selectedTalents.includes(id);
			const selectedTalents = isSelected ? prev.selectedTalents.filter((talentId) => talentId !== id) : [...prev.selectedTalents, id];
			return { ...prev, selectedTalents };
		});
		setPicked((prev) => {
			const isSelected = prev.some((talent) => talent.talent.id === id);
			const picked = isSelected ? prev.filter((talent) => talent.talent.id !== id) : [...prev, applicants.find((applicant) => applicant.talent.id === id)];
			return picked;
		});
	}

	const selectedTalents = applicants.filter((applicant) => hire.selectedTalents.includes(applicant.talent.id));

	const otherApplicants = applicants.filter((applicant) => !hire.selectedTalents.includes(applicant.talent.id));

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#FFFBF2', paddingBottom: '30px' }}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<div className="mb-3 d-flex align-items-center gap-1" style={{ color: '#CC5600', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
								<i class="fa-solid fa-chevron-left"></i>
								<p>Back</p>
							</div>

							<h5>Welcome to Recruitment Wizard</h5>

							<div className="d-flex" style={{ gap: '20px' }}>
								<h1 className="mb-3 display">Let's get your Talent onboard!</h1>
								<img src="/img/svg/rocket.svg" alt="rocket" style={{ width: '60px' }} />
							</div>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="25" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01 shadow-lg" style={{ backgroundColor: 'white', padding: '15px', borderRadius: '15px' }}>
				<div className="con-form">
					<div className="form">
						<form>
							<div className="d-lg-flex justify-content-between p-2">
								<article>
									<h4>Select Candidates</h4>
									<p>Ensure you review Criteria before selecting candidates</p>
								</article>
								<Button text={'Review Criteria'} secondaryNull onClick={()=>navigate(`/dashboard/jobs/criteria/${jobId}`)}/>
							</div>

							<div className="con-context">
								<h5 className="title">Candidate(s)</h5>

								<div className="cards-b">
									{selectedTalents.map((result, idx) => {
										const isChecked = hire.selectedTalents.includes(result.talent?.id);
										return (
											<article key={idx}>
												<div className={'card'} style={{ height: '270px', width: '200px' }}>
													<div className={'header'}>
														<p>{`CAND${idx + 1}`}</p>
														<i className="fa-regular fa-user"></i>
													</div>
													<div class="checkbox-container">
														<input type="checkbox" id={`checkbox-${idx}`} name={`checkbox-${idx}`} checked={isChecked} onChange={() => handleSelectTalent(result.talent.id)} />
														<label htmlFor={`checkbox-${idx}`}></label>
													</div>
													<div className={'Cardbody'}>
														<div className={'img'}>
															<img src={result.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />
														</div>
														<div className={'text'}>
															<div style={{ display: 'flex', gap: '5px' }}>
																<h1 className="truncate-e" style={{ fontSize: '16px' }}>
																	{result?.applicant_first_name} {result?.applicant_last_name}
																</h1>
																<img src={result?.isProfileApproved ? '/img/svg/verified.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '15px' }} />
															</div>
															<h3 className="my-2 text-truncate">{result?.title}</h3>
															<p className="d-flex align-items-center gap-2 truncate-e">
																<i className="fa-solid fa-location-dot"></i>
																{result.talent?.location?.resident_state || `Lagos`}, {result.talent?.location?.resident_country || `Nigeria`}
															</p>
														</div>
													</div>
												</div>
											</article>
										);
									})}
								</div>
							</div>

							<div className="con-context">
								<h5 className="title">Other Candidate(s)</h5>

								<section>
									{otherApplicants < 1 ? (
										<div style={{ margin: '20px 0' }}>
											<p>No other candidates to select from</p>
										</div>
									) : (
										<div className="cards-b">
											{otherApplicants.map((result, idx) => {
												const isChecked = hire.selectedTalents.includes(result.talent?.id);
												return (
													<article key={idx}>
														<div className={'card'} style={{ height: '270px' }}>
															<div className={'header'}>
																<p>{`CAND${idx + 1}`}</p>
																<i className="fa-regular fa-user"></i>
															</div>
															<div class="checkbox-container">
																<input type="checkbox" id={`checkbox-${idx}`} name={`checkbox-${idx}`} checked={isChecked} onChange={() => handleSelectTalent(result.talent.id)} />
																<label htmlFor={`checkbox-${idx}`}></label>
															</div>
															<div className={'Cardbody'}>
																<div className={'img'}>
																	<img src={result.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />
																</div>
																<div className={'text'}>
																	<div style={{ display: 'flex', gap: '5px' }}>
																		<h1 className="truncate-e" style={{ fontSize: '16px' }}>
																			{result?.applicant_first_name} {result?.applicant_last_name}
																		</h1>
																		<img src={result?.isProfileApproved ? '/img/svg/verified.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '15px' }} />
																	</div>
																	<h3 className="my-2 text-truncate">{result?.title}</h3>
																	<p className="d-flex align-items-center gap-2 truncate-e">
																		<i className="fa-solid fa-location-dot"></i>
																		{result.talent?.location?.resident_state || `Lagos`}, {result.talent?.location?.resident_country || `Nigeria`}
																	</p>
																</div>
															</div>
														</div>
													</article>
												);
											})}
										</div>
									)}
								</section>
							</div>

							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={() => navigate({ search: `?section=3` })} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;
