import { useEffect, useRef, useState } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/Onboarding.style.sass';
import { navbarComp } from '../dashboard/recruiter/navbar-components/navbar-comp';
import ChoosePlan from './section-1/ChoosePlan';
import SectionA from './section-2/SectionA';
import SectionB from './section-3/SectionB';
import SectionC from './section-4/SectionC';
import SectionD from './section-5/SectionD';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SuccessModal from 'views/profile/talent/modals/SuccessModal';
import api from 'services/api';



function Hire() {
	const top = useRef(null);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  
  const initialHireState = {
    selectedTalents: [],
    duration: '',
    talentType: "",
    salary: '',
    numberOfPositions: ''
  };
	const initialPicked = []

  const [hire, setHire] = useState(() => {
    const storedHire = sessionStorage.getItem('hire');
    return storedHire ? JSON.parse(storedHire) : initialHireState;
  });

  const [picked, setPicked] = useState(()=>{
		const storedPicked = sessionStorage.getItem('picked');
		return storedPicked ? JSON.parse(storedPicked) : initialPicked
	});
  const [job, setJob] = useState('');

  useEffect(() => {
    if (top.current) {
      top.current.scrollIntoView({ behavior: 'smooth' });
    }
    service.setPageTitle('Talent Hire');
  }, []);

  useEffect(() => {
    function onSuccess(response) {
      const result = response.data?.result;
      setJob(result);
    }
    api.recruiter.getJob(jobId).then(onSuccess).catch(console.error);
  }, [jobId]);

  useEffect(() => {
    sessionStorage.setItem('hire', JSON.stringify(hire));
    sessionStorage.setItem('picked', JSON.stringify(picked));
  }, [hire, picked]);

  const handleSubmit = () => {
    api.recruiter.hireTalent(jobId, hire).then(res => {
      if (res.status === 200) {
        setShowSuccessModal(true);
        setTimeout(() => {
          sessionStorage.removeItem('hire'); 
          navigate('/dashboard');
        }, 5000);
      }
    }).catch(res => {
      console.log(res);
      alert(res.data?.result);
    });
  };

	return (
		<div id="Onboarding_Main_Component" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				{query.get('section') === '1' && <ChoosePlan />}
				{query.get('section') === '2' && <SectionA hire={hire} picked={picked} setPicked={setPicked} setHire={setHire} />}
				{query.get('section') === '3' && <SectionB hire={hire} setHire={setHire} job={job}/>}
				{query.get('section') === '4' && <SectionC hire={hire} picked={picked} job={job}/>}
				{query.get('section') === '5' && <SectionD hire={hire} picked={picked} job={job} onSubmit={handleSubmit} />}

				{showSuccessModal && <SuccessModal text="Talent Hired!" handleClose={() => {setShowSuccessModal(false);navigate('/dashboard')}} modalTitle={'Talent Hire'} modalMessage={'Your Talent is on the way...'} />}

			</div>

			<Footer />
		</div>
	);
}

export default Hire;
