import './css/Ribbons.style.sass';

function Ribbons({ positionRef }) {
	return (
		<div id="Ribbons_Main_Container" ref={positionRef}>
			<div className="con-ribbon">
				<ul className="d-flex thebar" style={{ overflowX: 'scroll' }}>
					<li>
						<img src="https://images.pexels.com/photos/1405963/pexels-photo-1405963.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Project Managers</p>
					</li>
					<li>
						<img src="https://images.pexels.com/photos/3768911/pexels-photo-3768911.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Product Designers</p>
					</li>
					<li>
						<img src="https://images.pexels.com/photos/936117/pexels-photo-936117.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Developers</p>
					</li>
					<li>
						<img src="https://images.pexels.com/photos/7709242/pexels-photo-7709242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Frontend Developers</p>
					</li>
					<li>
						<img src="https://images.pexels.com/photos/6282048/pexels-photo-6282048.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Graphic Designers</p>
					</li>
					<li>
						<img src="https://images.pexels.com/photos/2216607/pexels-photo-2216607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
						<p>Scrum Masters</p>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Ribbons;
