import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionA.style.sass';

function SectionA({ context, isLoading, callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const errorRef = useRef(undefined);

	const [talent, setTalent] = useState('');
	const [selectedTalent, setSelectedTalent] = useState([]);
	const [throwError, setThrowError] = useState(false);

	// ARRAY OF AVAILABLE TALENTS FROM API
	const allTalents = [{ title: 'Product Designer' }, { title: 'Product Manager' }, { title: 'Front-end Developer' }, { title: 'Back-end Developer' }, { title: 'Quality Assurance Tester' }, { title: 'DevOps Engineer' }];

	const removeActiveState = () => {
		document.querySelectorAll('#con-talent-box').forEach((x) => x.classList.remove('active'));
	};

	const setActiveState = (index) => {
		document.querySelectorAll('#con-talent-box')[index].classList.add('active');
	};

	const doSelectTalent = ({ currentTarget: ctg }, index) => {
		if (!talent) {
			removeActiveState();
			setThrowError(false);
			if (selectedTalent.some((x) => x.id === index)) {
				ctg.classList.remove('active');
				setSelectedTalent(selectedTalent.filter((x) => x.id !== index));
			} else {
				ctg.classList.add('active');
				setSelectedTalent([{ title: allTalents[index].title, id: index }]);
			}
		}
	};

	const handleTalentValue = ({ target: { value } }) => {
		if (!selectedTalent.length) {
			setThrowError(false);
			setTalent(value);
		}
	};

	const doSubmit = () => {
		if (!selectedTalent.length && !talent) {
			setThrowError(true);
			service.goTo(errorRef);
		} else {
			let body = selectedTalent[0]?.title || talent;
			callback(body);
		}
	};

	function getJobTitle() {
		const found = allTalents.some((talent) => talent.title.toLowerCase() === context?.title?.toLowerCase());
		const index = allTalents.findIndex((talent) => talent.title.toLowerCase() === context?.title?.toLowerCase());

		if (!found) {
			context?.title && setTalent(context?.title);
		} else {
			setActiveState(index);
			setSelectedTalent([{ title: context?.title, id: index }]);
		}
	}

	// eslint-disable-next-line
	useEffect(getJobTitle, [context]);

	return (
		<div id="JobFlow_SectionA_Main_Container" ref={top}>
			<div className="section">
				<div className="con-context container-01" ref={errorRef}>
					<div className="my-5">
						<div className="context">
							<div className="title">
								<h1 className="mb-3">Get your Desired Talents come to you</h1>
								<h2 style={{ color: `${throwError ? 'red' : ''}` }}>Choose a skill</h2>
							</div>

							<div className="con-talent">
								{allTalents.map(({ title }, idx) => (
									<div className="talent d-flex d-lg-block align-items-center flex-column" key={idx}>
										<div className="con-box" id="con-talent-box" onClick={(e) => doSelectTalent(e, idx)}>
											<div className="img">
												<img src="/img/designwr 2.png" alt="" />
											</div>
											<div className="con-checkbox">
												<div className="checkbox"></div>
											</div>
										</div>
										<p className="caption mt-2">{title}</p>
									</div>
								))}
							</div>
						</div>

						<div className="con-search">
							<p className="title">Skill not found? Enter your preference</p>
							<div className="con-input">
								<input type="text" placeholder="Add Skills" value={talent} onChange={handleTalentValue} disabled={!!selectedTalent.length} />
							</div>
						</div>

						<div>
							<Button
								text={
									<>
										<h1>Next</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								disabled={isLoading}
								onClick={doSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;
