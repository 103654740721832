import Button from 'components/buttons/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';

function Buttons({ hasApplied, declineModal, deleteModal, talent, job }) {
	const ACTIVE_ROLE = service.getUserType();
	const [isLoading] = useState(false);
	const navigate = useNavigate()


	return (
		<>
			{ACTIVE_ROLE === service.RECRUITER && (
				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 gap-lg-2">
					<Button small fadeFill onClick={''} text={<h1>Report</h1>} />
					<Button small fadeFill onClick={''} text={<><i class="fa-solid fa-thumbs-up"></i></>} disabled={isLoading} />
					<Button small fadeFill onClick={''} text={<><i class="fa-solid fa-thumbs-down"></i></>} disabled={isLoading} />
					<Button
						small
						fadeFill
						text={
							<>
								<i class="fa-solid fa-message"></i>
								<h1>Contact Talent</h1>
							</>
						}
					/>
					<Button
						small
						primaryNull
						text={
							<>
								<i class="fa-solid fa-suitcase"></i>
								<h1>Hire</h1>
							</>
						}
						onClick={() => navigate(`/dashboard/jobs/recruiter/hire/${job}/${talent}?section=1`)}
					/>
				</div>
			)}
		</>
	);
}

export default Buttons;
