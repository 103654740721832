import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import UploadViewer from 'components/upload-viewer/UploadViewer';
import { useEffect, useState } from 'react';
import service from 'services';
import api from 'services/api';
import style from 'views/jobs/job-details/modals/css/Modal.module.sass';

function ModalBodyA({ callback }) {
	const [user, setUser] = useState({});

	function getUser() {
		function successHandler(response) {
			const result = response?.data?.result;
			setUser(result);
		}

		service.getUserType() === service.TALENT && api.talents.profile.get().then(successHandler).catch(console.error);
		service.getUserType() === service.FREELANCER && api.freelancers.profile.get().then(successHandler).catch(console.error);
	}

	useEffect(getUser, []);

	const [form, setForm] = useState({
		applicant_first_name: user?.first_name,
		applicant_last_name: user?.last_name,
		applicant_email: user?.email,
		applicant_phone_number: '',
		cv: '',
		cover_letter: '',
	});

	const [formErrors, setFormErrors] = useState(form);

	const handleOnChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setForm({ ...form, [`${name}`]: value });
		setFormErrors({ ...formErrors, [`${name}`]: `` });
	};

	const handleFileChange = (e) => {
		const name = e.target.name;
		const files = e.target.files;
		if (files) {
			if (files[0]?.size) {
				setForm({ ...form, [`${name}`]: files[0] });
				setFormErrors({ ...formErrors, [`${name}`]: `` });
			}
		}
	};

	const clearFile = (field) => {
		setForm({ ...form, [`${field}`]: '' });
	};

	const hasErrors = (exceptionList) => {
		for (const field in form) {
			if (!form[field] && !exceptionList.includes(field)) {
				const formElement = document.forms[0].elements;
				formElement[field]?.focus();
				formElement[field]?.scrollIntoView({ block: 'center' });
				setFormErrors({ ...formErrors, [`${field}`]: `field is required` });
				console.warn(`{${field}} field is required`);

				return true;
			}
		}
		return false;
	};

	const configFields = (form) => {
		form.applicant_first_name = user?.first_name;
		form.applicant_last_name = user?.last_name;
		form.applicant_email = user?.email;
	};

	const handleSubmit = () => {
		configFields(form);
		if (!hasErrors(['cover_letter'])) {
			callback(form);
		}
	};

	return (
		<div className={`${style.container} ${style['container-1']}`}>
			<div className={style.header}>
				<ProgressBar percent="50" />
			</div>

			<div className={style['con-form']}>
				<h2 className={style.title}>Personal Info</h2>

				<form>
					<div className="d-lg-flex justify-content-between gap-4">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>First Name</label>
							</div>

							<input type="text" placeholder={user?.first_name} disabled />
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Last Name</label>
							</div>

							<input type="text" placeholder={user?.last_name} disabled />
						</div>
					</div>

					<div className="d-lg-flex justify-content-between gap-4">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Email Address</label>
							</div>

							<input type="text" placeholder={user?.email} disabled />
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Phone Number *</label>
								<label className="error-msg mb-3">{formErrors.applicant_phone_number}</label>
							</div>

							<input type="text" name="applicant_phone_number" placeholder="Enter phone number" className={formErrors.applicant_phone_number ? 'error' : ''} value={form.applicant_phone_number} onChange={handleOnChange} />
						</div>
					</div>

					<div className="d-lg-flex justify-content-between gap-4">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Linked Profile URL</label>
							</div>

							<input type="text" placeholder="Enter LinkedIn Profile URL" />
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Portfolio URL</label>
							</div>

							<input type="text" placeholder="Enter Portfolio URL" />
						</div>
					</div>

					<div className="d-lg-flex justify-content-between gap-4">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Resume *</label>
								<label className="error-msg mb-3">{formErrors.cv}</label>
							</div>

							{form.cv && <UploadViewer filename={form.cv.name} callback={() => clearFile('cv')} />}

							<div className="mt-3">
								<div className={`${style['con-upload']}`}>
									<label className={`${style.upload}`}>
										{form.cv ? `Change CV` : `Attach CV`}
										<input name="cv" type="file" accept={`application/pdf,application/msword`} onChange={handleFileChange} />
									</label>
								</div>
							</div>
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Cover Letter</label>
								<label className="error-msg mb-3">{formErrors.cover_letter}</label>
							</div>

							{form.cover_letter && <UploadViewer filename={form.cover_letter.name} callback={() => clearFile('cover_letter')} />}

							<div className="mt-3">
								<div className={`${style['con-upload']}`}>
									<label className={`${style.upload}`}>
										{form.cover_letter ? `Change Cover Letter` : `Attach Cover Letter`}
										<input name="cover_letter" type="file" accept={`application/pdf,application/msword`} onChange={handleFileChange} />
									</label>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div className="footer d-lg-flex align-items-center gap-3">
				<div className="mb-4 mb-lg-0">
					<Button
						small
						text={<h2>Cancel</h2>}
						primaryNull
						{...{
							doDismissModal: true,
						}}
					/>
				</div>

				<div className="mb-4 mb-lg-0">
					{!form.applicant_phone_number || !form.cv ? (
						<Button
							small
							text={
								<>
									<h2>Next (Questions) </h2>
									<i className="fa-solid fa-arrow-right"></i>
								</>
							}
							onClick={handleSubmit}
						/>
					) : (
						<Button
							small
							text={
								<>
									<h2>Next (Questions) </h2>
									<i className="fa-solid fa-arrow-right"></i>
								</>
							}
							onClick={handleSubmit}
							{...{
								modal: true,
								doDismissModal: true,
								modalTarget: 'job-details-modal-b',
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ModalBodyA;
