// const baseUrl = 'https://backend-prod.frontters.com/api/v1';
const baseUrl = process.env.REACT_APP_FRONTERS_BASE_URL
const authUrl = `${baseUrl}/auth`;


const routes = {
	register: (userType, rest) => `${authUrl}/${userType}/signup/${rest ? rest : ''}`,
	'google-register': (userType) => `${authUrl}/${userType}/google`,
	'facebook-register': (userType) => `${authUrl}/${userType}/facebook`,
	login: (userType) => `${authUrl}/${userType}/login`,
	'google-login': () => `${authUrl}/generic/google`,
	'facebook-login': () => `${authUrl}/generic/facebook`,
	'parse-cv': () => `${authUrl}/parse-cv`,
	'refresh-token': () => `${baseUrl}/auth/refresh-token`,
	'verify-account': () => `${baseUrl}/auth/verify-email-token`,
	're-verify-account': () => `${baseUrl}/auth/resend-email-token`,
	'contact-us': () => `${baseUrl}/frontters/contact-us`,
	'get-users': () => `${baseUrl}/frontters/users`,
	'reset-password-request': () => `${authUrl}/reset-password-request`,
	'reset-password': () => `${authUrl}/reset-password`,

	recruiter: {
		url: () => `${baseUrl}/recruiters`,
		jobs: () => `${routes.recruiter.url()}/jobs`,
		'job-id': (id) => `${routes.recruiter.url()}/jobs/${id}`,
		'company-logo': () => `${routes.recruiter.url()}/avatar`,
		'company-cac': () => `${routes.recruiter.url()}/cac-upload`,
		'find-talents': () => `${routes.recruiter.url()}/talents/find/`,
		'get-talent': (id) => `${routes.recruiter.url()}/talents/${id}`,
		'get-talents-project': (id) => `${routes.recruiter.url()}/talents/projects/${id}`,
		'get-talents-single-project': (projectId) => `${routes.recruiter.url()}/talents/projects/details/${projectId}`,
		'get-job-titles': () => `${routes.recruiter.url()}/jobs/job-titles`,
		'jobs-stats': () => `${routes.recruiter.jobs()}/stats`,
		'get-applied-jobs': () => `${routes.recruiter.jobs()}/applied`,
		'get-job-applicants': (id) => `${routes.recruiter.jobs()}/${id}/applicants`,
		'hire-talent': (id) => `${routes.recruiter.jobs()}/${id}/hire`,
		profile: {
			'get-details': () => `${routes.recruiter.url()}`,
			'upload-image': () => `${routes.recruiter.url()}/profile-avatar`,
			'upload-company-image': () => `${routes.recruiter.url()}/logo-avatar`,
		},
	},
	talents: {
		feedback: () => `${baseUrl}/feedback`,
		url: () => `${baseUrl}/talents`,
		refer: () => `${routes.talents.url()}/refer`,
		profile: {
			'get-details': () => `${routes.talents.url()}`,
			'get-complete': () => `${routes.talents.url()}/completeness`,
			'upload-image': () => `${routes.talents.url()}/avatar`,
			'add-certificate': () => `${routes.talents.url()}/certs`,
			'get-certificates': (certId) => `${routes.talents.url()}/certs/${certId}`,
			'delete-certificate': (certId) => `${routes.talents.url()}/certs/${certId}`,
		},
		jobs: {
			id: (jobId) => `${routes.talents.url()}/jobs/${jobId}`,
			recommended: () => `${routes.talents.url()}/jobs/recommended`,
			'apply-to-job': (jobId) => `${routes.talents.url()}/jobs/${jobId}`,
			'decline-apply-to-job': (applicationId$) => `${routes.talents.url()}/jobs/applied/${applicationId$}`,
			'add-experience': () => `${routes.talents.url()}/experience`,
			'delete-experience': (experienceId) => `${routes.talents.url()}/experience/${experienceId}`,
			'add-education': () => `${routes.talents.url()}/education`,
			'delete-education': (educationId) => `${routes.talents.url()}/education/${educationId}`,
			bookmark: () => `${routes.talents.url()}/jobs/favorites`,
			'bookmark-by-id': (jobId) => `${routes.talents.url()}/jobs/favorites/${jobId}`,
			'search-jobs': () => `${routes.talents.url()}/jobs`,
			'get-applied-jobs': () => `${routes.talents.url()}/jobs/applied`,
			'report-job': (jobId) => `${routes.talents.url()}/jobs/report/${jobId}`,
			'job-titles': () => `${baseUrl}/jobs/job-titles`
		},
		projects: {
			'create-project': () => `${routes.talents.url()}/projects`,
			'delete-project': (projectId) => `${routes.talents.url()}/projects/${projectId}`,
			'project-by-id': (projectId) => `${routes.talents.url()}/projects/${projectId}`,
		},
	},
	freelancers: {
		url: () => `${baseUrl}/freelancers/talents`,
		refer: () => `${routes.freelancers.url()}/refer`,
		profile: {
			'get-details': () => `${routes.freelancers.url()}`,
			'get-complete': () => `${routes.freelancers.url()}/completeness`,
			'upload-image': () => `${routes.freelancers.url()}/avatar`,
			'add-certificate': () => `${routes.freelancers.url()}/certs`,
			'get-certificates': (certId) => `${routes.freelancers.url()}/certs/${certId}`,
			'delete-certificate': (certId) => `${routes.freelancers.url()}/certs/${certId}`,
		},
		jobs: {
			id: (jobId) => `${routes.freelancers.url()}/jobs/${jobId}`,
			recommended: () => `${routes.freelancers.url()}/jobs/recommended`,
			'apply-to-job': (jobId) => `${routes.freelancers.url()}/jobs/${jobId}`,
			'decline-apply-to-job': (applicationId$) => `${routes.freelancers.url()}/jobs/applied/${applicationId$}`,
			'add-experience': () => `${routes.freelancers.url()}/experience`,
			'delete-experience': (experienceId) => `${routes.freelancers.url()}/experience/${experienceId}`,
			'add-education': () => `${routes.freelancers.url()}/education`,
			'delete-education': (educationId) => `${routes.freelancers.url()}/education/${educationId}`,
			bookmark: () => `${routes.freelancers.url()}/jobs/favorites`,
			'bookmark-by-id': (jobId) => `${routes.freelancers.url()}/jobs/favorites/${jobId}`,
			'search-jobs': () => `${routes.freelancers.url()}/jobs`,
			'get-applied-jobs': () => `${routes.freelancers.url()}/jobs/applied`,
		},
		projects: {
			'create-project': () => `${routes.freelancers.url()}/projects`,
			'delete-project': (projectId) => `${routes.freelancers.url()}/projects/${projectId}`,
			'project-by-id': (projectId) => `${routes.freelancers.url()}/projects/${projectId}`,
		},
	},
	admin: {
		url: () => `${baseUrl}/admins`,
		skills: {
			'skill': () => `${routes.admin.url()}/skills`,
		}
	}
};

export default routes;
