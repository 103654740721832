import BreadCrumb from 'components/bread-crumb/BreadCrumb.jsx';
import JobCard from 'components/cards/job-card/JobCard';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination.jsx';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import './css/AppliedJobs.style.sass';
// import { useNavigate } from 'react-router-dom';

function PostedJobsViewByRecruiter() {
	service.setPageTitle('My Posted Jobs');
	const userType = service.getUserType();
	// const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [isLoading, setIsLoading] = useState(false);
	const [postedJobs, setPostedJobs] = useState([]);

	function fetchPosted() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			console.log(response)
			setPostedJobs(result.filter((job) => job?.status === 'published'));
		}
		function errorHandler(response) {
			console.error(response);
		}
		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.getMyJobs().then(successHandler).catch(errorHandler).finally(onFinal);
	}

	useEffect(fetchPosted, [userType]);

	const breadCrumbProp = [
		{
			title: 'Home',
			link: '',
			previous: true,
		},
		{
			title: 'Posted Jobs',
			link: '',
		},
	];

	return (
		<div id="Applied_Jobs_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap" style={{ border: '3px solid black' }}>
						<div className="con-header mx-4 py-4">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Posted Jobs</h2>

							<div className="body">
								{!isLoading &&
									postedJobs?.length > 0 &&
									postedJobs.map((job, idx) => {
										return (
											<JobCard key={1} data={job} />
										);
									})}
							</div>
							{!isLoading && postedJobs?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src="/img/oops.png" alt="oops" className="img-fluid w-25" />
									<h3 className="my-2">Oops!</h3>
									<p>No posted jobs yet</p>
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>

						{postedJobs?.length > 9 && (
							<div className="footer">
								<IndexPagination />
							</div>
						)}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default PostedJobsViewByRecruiter;
