import React from 'react';

const ChatWindow = ({ chat, onBack }) => {
  if (!chat) return <div>Select a chat to view messages</div>;

  return (
    <section style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
       <div className="d-md-none p-2">
        <button className="btn btn-link" onClick={onBack}>
          &larr; Back
        </button>
      </div>
      <div style={{ backgroundColor: '#2E2E30', padding: '15px 20px', borderRadius: '10px 10px 0 0' }}>
        <div style={{ display: 'flex', gap: '20px' }}>
          <h5 style={{ color: '#D5D5D6' }}>{chat.name}</h5>
          <span style={{ fontSize: '13px', color: '#D5D5D6', backgroundColor: '#CC5600', borderRadius: '1000px', padding: '2px 6px' }}>{'1 unread message'}</span>
        </div>
      </div>
      <div className="flex-grow-1 overflow-auto p-3 shadow mb-1" style={{ minHeight: '500px', height: '100%', backgroundColor: 'white' }}>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom:'20px' }}>
          <img src="/img/svg/ensure.svg" alt="" style={{width:'40px'}}/>
          <p>Ensure you don’t share personal information like email address, phone numbers and other sensitive information during your conversations</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {chat.messages.map((message) => (
            <div
              key={message.id}
              style={{
                display: 'flex',
                justifyContent: message.sender === 'sent' ? 'flex-end' : 'flex-start',
              }}
            >
              <div
                style={{
                  maxWidth: '60%',
                  padding: '10px',
                  borderRadius: '15px',
                  backgroundColor: message.sender === 'sent' ? '#DCF8C6' : '#F1F0F0',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                  wordBreak: 'break-word',
                  position: 'relative',
                }}
              >
                <div style={{ filter: message.blocked ? 'blur(5px)' : 'none' }}>
                  {message.text}
                </div>
                {/* {message.blocked && (
                  <div style={{
                    position: 'absolute',
                    bottom: '-30px',
                    left: '0',
                    right: '0',
                    borderRadius: '0 0 15px 15px',
                    textAlign: 'center',
                    padding: '2px 0',
                    color: '#FF0000',
                    fontWeight: 'bold',
                  }}>
                    Blocked
                  </div>
                )} */}
              </div>
            </div>
          ))}

        </div>
      </div>
      <div className="d-flex gap-1" style={{ marginTop: 'auto', width: '100%', alignItems: 'center', backgroundColor: 'white', padding: '10px' }}>
        <article className='' style={{ width: '80%', backgroundColor: '#EBEBEB', borderRadius: '1000px', padding: '10px', display: 'flex', alignItems: 'center' }}>
          <figure style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
            <img src="/img/svg/attach-gray.svg" alt="" style={{ width: '25px' }} />
          </figure>
          <input type="text" placeholder="Keep the conversation going!" style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', flex: 1, padding: '5px' }} />
        </article>
        <article className='d-flex gap-1' style={{ width: '20%', alignItems: 'center' }}>
          <img src="/img/svg/suggest.svg" alt="" style={{ width: '40px' }} />
          <div style={{ border: '1px solid #CC5600', padding: '7px', borderRadius: '10px' }}><img src="/img/svg/send.svg" alt=""  style={{width: '30px'}}/></div>
        </article>
      </div>
    </section>
  );
};

export default ChatWindow;
