import Button from 'components/buttons/Button';
// import Toggle from 'components/buttons/toggle/Toggle';
import JobCard from 'components/cards/job-card/JobCard';
import Message from 'components/message-api/Message';
import Modal from 'components/modals/Modal';
import DotPagination from 'components/pagination/pagination-dot/DotPagination';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api/index.js';
import classNames from 'utils/conditional-css-classes/classNames';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import ProfileLevel from 'views/dashboard/components/profile-level/ProfileLevel';
import ContractPreferenceModal from 'views/profile/talent/modals/ContractPreferenceModal';
import './css/Talent.style.sass';
import { navbarComp } from './navbar-components/navbar-comp';
import FeedBackModal from 'views/profile/talent/modals/FeedBackModal';
import SuccessModal from 'views/profile/talent/modals/SuccessModal';

function Talent() {
	service.setPageTitle('Talent Dashboard');
	const userType = service.getUserType();

	const cardARef = useRef(undefined);
	const [jobAvailable, setJobAvailable] = useState(false);

	const [recommended, setRecommended] = useState([]);
	const [appliedJobs, setAppliedJobs] = useState([]);
	const [user, setUser] = useState({});

	const [complete, setComplete] = useState({});
	// const [isVisible, setIsVisible] = useState(false);
	const contractPreference = useMemo(() => ['full-time', 'part-time'], []);
	const [contractPreferenceIdx, setContractPreferenceIdx] = useState(0);

	const [isLoading, setIsLoading] = useState(true);
	const [isModalLoading, setModalIsLoading] = useState(false);

	const [isLoadingUser, setIsLoadingUser] = useState(true);
	const [message, setMessage] = useState([]);
	const [search, setSearch] = useState('');

	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const navigate = useNavigate();

	const [feedback, setFeedback] = useState({
		description: '',
		reason: '',
	});

	const handleFeedbackChange = (e) => {
		const { name, value } = e.target;
		setFeedback((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// the pagination for this page
	const itemsPerPage = 2;
	const [recommendedPageIndex, setRecommendedPageIndex] = useState(0);
	const [appliedPageIndex, setAppliedPageIndex] = useState(0);

	const handleRecommendedPageChange = (newPageIndex) => {
		setRecommendedPageIndex(newPageIndex);
	};

	const handleAppliedPageChange = (newPageIndex) => {
		setAppliedPageIndex(newPageIndex);
	};

	const getDisplayedJobs = (jobs, pageIndex) => {
		const startIndex = pageIndex * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return jobs.slice(startIndex, endIndex);
	};

	const [currentPage, setCurrentPage] = useState(1);

	const pageCount = Math.ceil(recommended.length / 4);

	const onPageChange = (newPage) => {
		if (newPage >= 1 && newPage <= pageCount) {
			setCurrentPage(newPage);
		}
	};

	const startIndex = (currentPage - 1) * 4;
	const endIndex = startIndex + 4;
	const displayedJobs = recommended.slice(startIndex, endIndex);

	const displayedRecommendedJobs = getDisplayedJobs(recommended, recommendedPageIndex);
	const displayedAppliedJobs = getDisplayedJobs(appliedJobs, appliedPageIndex);

	function getJobs() {
		function successHandler(response) {
			const result = response?.data?.result;
			setRecommended(result.filter((job) => job?.status === 'published' && job?.isJobApproved !== false));
		}

		function onFinal() {
			setIsLoading(false);
		}

		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
				api.talents.jobs
					.appliedJobs()
					.then((res) => setAppliedJobs(res?.data?.result))
					.catch(console.error)
					.finally(onFinal);
				return;
			case service.FREELANCER:
				api.freelancers.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
				api.freelancers.jobs
					.appliedJobs()
					.then((res) => setAppliedJobs(res?.data?.result))
					.catch(console.error)
					.finally(onFinal);
				return;
			default:
				break;
		}
	}

	function getUser() {
		function successHandler() {
			return [
				(response) => {
					const result = response?.data?.result;
					const index = contractPreference.findIndex((contract) => contract.toLowerCase() === result?.full_time_decision?.toLowerCase());

					setUser(result);
					// setIsVisible(result.isVisible);
					setContractPreferenceIdx(index);
					setJobAvailable(result?.isAvailable);
				},
				(response) => {
					const result = response?.data?.result;
					setComplete(result);
				},
			];
		}

		function handleError(response) {
			try {
				if (response.data.success === false) {
					service.reloadPage();
				}
			} catch (error) {
				console.error('An error occurred:', error);
			}
		}

		function onFinal() {
			setIsLoadingUser(false);
		}

		if (userType === 'talent') {
			api.talents.profile.get().then(successHandler()[0]).catch(handleError).finally(onFinal);
			api.talents.profile.completeStatus().then(successHandler()[1]).catch(handleError).finally(onFinal);
		} else {
			api.freelancers.profile.get().then(successHandler()[0]).catch(handleError).finally(onFinal);
			api.freelancers.profile.completeStatus().then(successHandler()[1]).catch(handleError).finally(onFinal);
		}
	}

	useEffect(getJobs, [userType]);
	useEffect(getUser,[userType,contractPreference]);

	const handleCardAScroll = () => {
		if (cardARef.current.scrollLeft % 3 === 0) {
		} else if (cardARef.current.scrollLeft % 2 === 0) {
		} else {
		}
	};

	// function doHandleVisibility() {
	// 	setIsVisible(!isVisible);

	// 	function successHandler(response) {
	// 		const result = response?.data?.result;
	// 		setIsVisible(result.isVisible);
	// 	}

	// 	if (userType === 'talent') {
	// 		api.talents.profile.update({ isVisible: !isVisible }).then(successHandler).catch(console.error);
	// 	} else {
	// 		api.freelancers.profile.update({ isVisible: !isVisible }).then(successHandler).catch(console.error);
	// 	}
	// }

	function doHandleAvailability() {
		setJobAvailable(!jobAvailable);

		function successHandler(response) {
			const result = response?.data?.result;
			setJobAvailable(result.isAvailable);
		}

		if (userType === 'talent') {
			api.talents.profile.update({ isAvailable: !jobAvailable }).then(successHandler).catch(console.error);
		} else {
			api.freelancers.profile.update({ isAvailable: !jobAvailable }).then(successHandler).catch(console.error);
		}
	}
	function doHandleContractPreference(idx) {
		setContractPreferenceIdx(idx);

		function successHandler(response) {
			const result = response?.data?.result;
			const index = contractPreference.findIndex((contract) => contract.toLowerCase() === result?.full_time_decision?.toLowerCase());
			setContractPreferenceIdx(index);
		}

		if (userType === 'talent') {
			api.talents.profile.update({ full_time_decision: contractPreference[idx] }).then(successHandler).catch(console.error);
		} else {
			api.freelancers.profile.update({ full_time_decision: contractPreference[idx] }).then(successHandler).catch(console.error);
		}
	}

	function selectContractPreference({ idx }) {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `update-contract-preference-${idx}`,
			modalTitle: 'warning',
			modalBody: <ContractPreferenceModal index={idx} callback={doHandleContractPreference} />,
		};
	}

	const handleCloseSuccessModal = () => {
		setShowSuccessModal(false);
	};
	const handleCancel = () => {
		setShowModal(false);
	};

	function toggleSave(id) {
		function successHandler() {
			setMessage([...message, { text: 'Job saved successfully' }]);
			clearMessages();
		}
		function errorHandler(response) {
			const result = response.data.result;
			setMessage([...message, { text: result }]);
			clearMessages();
		}

		if (userType === 'talent') {
			api.talents.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);
		} else {
			api.freelancers.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);
		}
	}

	function clearMessages() {
		function clear() {
			setMessage([]);
		}

		setTimeout(clear, service.errorMilliSecond);
	}

	const handleOnChange = (e) => {
		const value = e.target.value;
		setSearch(value);
	};

	const doFeedback = () => {
		setModalIsLoading(true);
		api.talents
			.createFeedback(feedback)
			.then((res) => {
				if (res.status === 200) {
					setShowModal(false);
					setShowSuccessModal(true);
					setFeedback(feedback);
				}
			})
			.catch((res) => console.log(res))
			.finally(() => setModalIsLoading(false));
	};

	const handleSubmit = (e) => {
		e?.preventDefault();
		if (search.trim()) {
			navigate({ pathname: '/dashboard/search', search: `type=${search}&search=${search}&callback=find-job` });
		}
	};

	return (
		<div id="Dashboard_Talent_Main_Container">
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="row mb-5">
						<div className="col-12 col-lg-8 mb-5 mb-lg-0 ">
							<div className="con-section-1 h-100">
								<div className="section-a">
									<div className="title">
										<h2>Recommended just for you</h2>
										<p className="mt-2 mb-4">Based on your profile</p>
									</div>

									<div className="body" ref={cardARef} onScroll={handleCardAScroll}>
										<div className="con-card-job con-card-jod-e ">
											{!isLoading &&
												recommended.length > 0 &&
												displayedRecommendedJobs.map((job, y) => (
													<div className="card-job" key={y}>
														<div className="header d-flex align-items-center justify-content-between">
															<div className="d-flex align-items-center">
																{[1, 1, 1].map((_, y) => (
																	<div className={`box box-${y + 1}`} key={y}></div>
																))}
															</div>

															<i className="fa-solid fa-xmark"></i>
														</div>

														<div className="context d-flex align-items-start gap-3">
															<div className="con-class d-flex justify-content-between" style={{ width: '100%' }}>
																<div className="class-1" style={{ width: '90%' }}>
																	<h2 className="d-flex align-items-center gap-3" title={job.title}>
																		{service.truncateText(job.title, 18)} <i>{job.job_type}</i>
																	</h2>
																	<h3 className="mt-2 mb-3" title={job?.company_name}>
																		{job?.company_name ? service.truncateText(job?.company_name) : `company name`}
																	</h3>
																	<h4 className="mb-3" title={`${job.location.state} ${job.location.country}`}>
																		{service.truncateText(job.location.state, 12)}, {service.truncateText(job.location.country, 12)}
																	</h4>
																	<p>
																		{job?.currency}
																		{job.expected_pay.toLocaleString()}/yr
																	</p>
																</div>

																<div className="class-2 d-flex flex-column align-items-end justify-content-between" style={{ width: '10%' }}>
																	<i className="fa-regular fa-bookmark" onClick={() => toggleSave(job?._id)}></i>

																	<Link to={`/dashboard/jobs/${job?._id}`} className="arrow-btn outline">
																		<i className="fa-solid fa-arrow-right"></i>
																	</Link>
																</div>
															</div>
														</div>
													</div>
												))}
										</div>
										{!isLoading && recommended.length < 1 && (
											<div className="d-flex flex-column justify-content-center align-items-center text-center m-auto">
												<img src="img/oops.png" alt="oops" className="img-fluid w-25" />
												<h3 className="my-2">Oops!</h3>
												<p>Complete your profile to have recommended courses tailored to you</p>
											</div>
										)}
										<div className="con-animated-bg">{isLoading && [1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
									</div>

									{recommended.length > itemsPerPage && (
										<div className="d-flex justify-content-center mb-3">
											<DotPagination totalItems={recommended.length} itemsPerPage={itemsPerPage} activeIndex={recommendedPageIndex} onChange={handleRecommendedPageChange} />
										</div>
									)}

									<div className="footer">
										{recommended.length > 0 && (
											<Button
												secondaryNull
												text={
													<>
														<h2>View more Recommendations</h2>
														<i className="fa-solid fa-arrow-right"></i>
													</>
												}
												onClick={() => navigate({ pathname: '/dashboard/jobs/recommended-jobs' })}
											/>
										)}
									</div>
								</div>

								<form className="section-b outline-wrap" onSubmit={handleSubmit}>
									{/* <div className="con-select">
										<select>
											<option value="">Search for Opportunity</option>
										</select>
									</div> */}
									<div className="con-input my-3 my-lg-0">
										<input type="text" placeholder="Search for jobs by title, like Designer..." onChange={handleOnChange} />
									</div>
									<button className="con-submit d-flex d-lg-block" onClick={handleSubmit}>
										<div className="arrow-btn">
											<img src="/img/svg/search.svg" alt="" style={{ width: '18px', height: '18px' }} />
										</div>
									</button>
								</form>
								{recommended.length > 0 && (
									<div className="section-c">
										<div className="title">
											<h2 className="mt-2 mb-4">Jobs on {user?.title && service.titleCase(user?.title)}</h2>
										</div>

										<div className="body">
											<div className="con-card-job">{!isLoading && recommended.length > 0 && displayedJobs.map((job, y) => job.status === 'published' && <JobCard key={y} data={job} />)}</div>
											<div className="con-animated-bg">{isLoading && [1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
										</div>

										{recommended.length > 2 && (
											<div className="footer d-flex justify-content-center">
												<IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange} />
											</div>
										)}
									</div>
								)}

								<div className="section-a my-4">
									<div className="title">
										<h2>Jobs applied for</h2>
									</div>

									<div className="body" ref={cardARef} onScroll={handleCardAScroll}>
										<div className="con-card-job  con-card-jod-e">
											{!isLoading &&
												appliedJobs.length > 0 &&
												displayedAppliedJobs.map(({ job }, y) => {
													return (
														<div className="card-job" key={y}>
															<div className="header d-flex align-items-center justify-content-between">
																<div className="d-flex align-items-center">
																	{[1, 1, 1].map((_, y) => (
																		<div className={`box box-${y + 1}`} key={y}></div>
																	))}
																</div>

																<i className="fa-solid fa-xmark"></i>
															</div>

															<div className="context d-flex align-items-start gap-3">
																<div className="con-class d-flex justify-content-between" style={{ width: '100%' }}>
																	<div className="class-1" style={{ width: '90%' }}>
																		<h2 className="d-flex align-items-center gap-3">
																			{job?.title} <i>{job?.job_type}</i>
																		</h2>
																		<h3 className="mt-2 mb-3" title={job?.company_name}>
																			{job?.company_name ? service.truncateText(job?.company_name) : `company name`}
																		</h3>
																		<p>
																			{job?.currency}
																			{job?.expected_pay.toLocaleString()}/yr
																		</p>
																	</div>

																	<div className="class-2 d-flex flex-column align-items-end justify-content-between" style={{ width: '10%' }}>
																		<i className="fa-regular fa-bookmark" onClick={() => toggleSave(job?._id)}></i>

																		<Link to={`/dashboard/jobs/${job?._id}`} className="arrow-btn outline">
																			<i className="fa-solid fa-arrow-right"></i>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
											{!isLoading && appliedJobs.length < 1 && (
												<div className="d-flex flex-column justify-content-center align-items-center text-center " style={{ translate: '50%' }}>
													<img src="img/oops.png" alt="oops" className="img-fluid w-50" />
													<h3 className="my-2">Oops!</h3>
													<p>You haven't applied for any job</p>
												</div>
											)}
										</div>
										<div className="con-animated-bg">{isLoading && [1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
									</div>

									{appliedJobs.length > itemsPerPage && (
										<div className="d-flex justify-content-center mb-3">
											<DotPagination totalItems={appliedJobs.length} itemsPerPage={itemsPerPage} activeIndex={appliedPageIndex} onChange={handleAppliedPageChange} />
										</div>
									)}

									<div className="footer">
										{appliedJobs.length > 0 && (
											<Button
												secondaryNull
												text={
													<>
														<h2>View more Applied jobs</h2>
														<i className="fa-solid fa-arrow-right"></i>
													</>
												}
												onClick={() => navigate({ pathname: '/dashboard/jobs/applied-jobs' })}
											/>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="con-section-2">
								{!isLoadingUser && user && (
									<div className="con-user-dp">
										<div className="con-user-img">
											<div className="bg">
												<div className="img">
													<img src={user?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="" />
												</div>
											</div>
											<div className="icon"></div>
										</div>

										<div className="con-user-data d-flex flex-column align-items-center my-3">
											<div style={{ display: 'flex', gap: '5px' }}>
												<h2 className="user-name" title={`${user?.first_name} ${user?.last_name}`}>
													{user?.first_name && service.titleCase(user?.first_name)} {user?.last_name && service.titleCase(user?.last_name)}
												</h2>
												<img src={user?.isProfileApproved ? '/img/svg/verified.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
											</div>
											<p className="user-role" title={user?.title}>
												{user?.title && service.titleCase(service.truncateText(user?.title, 20))}
											</p>
											<Link to={'/dashboard/profile'} className="user-cta mt-3">
												View Profile
											</Link>
											<div className="user-visible"></div>
										</div>

										<div className="my-4">
											{/* <Toggle status={isVisible} label="Profile Visibility" callback={doHandleVisibility} /> */}
											<div className="my-4">
												<Button
													text={
														<div className="d-flex gap-2 align-content-center align-items-center">
															<img src="/img/svg/feedback.svg" alt="" style={{ width: '20px' }} />
															<p>Feedback</p>
														</div>
													}
													onClick={() => setShowModal(true)}
												/>
												{showModal && (
													<FeedBackModal
														option={true}
														title="Enter Your Question Here"
														modalTitle={'FEEDBACK'}
														details={feedback}
														Subject={'Subject'}
														subjects={[
															{ value: 'sub1', label: 'Sub 1' },
															{ value: 'sub2', label: 'Sub 2' },
															{ value: 'sub3', label: 'Sub 3' },
														]}
														placeHolderText={'What do you want to write about?'}
														handleChange={handleFeedbackChange}
														handleSubmit={doFeedback}
														onCancel={handleCancel}
														isLoading={isModalLoading}
													/>
												)}

												{showSuccessModal && <SuccessModal text="Feedback Sent!" handleClose={handleCloseSuccessModal} modalTitle={'FEEDBACK'} modalMessage={'Your feedback has been submitted successfully'} />}
											</div>
										</div>
									</div>
								)}
								<div className="con-animated-bg">{isLoadingUser && [1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
								{!isLoadingUser && user && (
									<div className="sections">
										<div className="mb-5">
											<ProfileLevel level={complete?.completeness_value} />
										</div>

										<div className="section-001">
											<div className="con-title">
												<h2>Job Availability</h2>
											</div>

											<div className="con-body d-flex align-items-center gap-2" onClick={doHandleAvailability}>
												<div className={`checkbox`} style={{ backgroundColor: jobAvailable && '#CC5600' }}>
													<div className={`checker ${classNames(jobAvailable && `active`)}`}></div>
												</div>
												<p>Open for jobs at the moment</p>
											</div>
										</div>

										{userType === 'talent' && (
											<div className="section-002 my-5">
												<div className="con-title">
													<h2>Contract Preference</h2>
												</div>

												<div className="con-body d-flex align-items-lg-center flex-column flex-lg-row gap-3">
													{contractPreference.map((x, y) => (
														<div key={y}>
															<Button primaryNull={contractPreference[contractPreferenceIdx] !== x} darkFill text={<h2>{service.titleCase(x)}</h2>} {...selectContractPreference({ idx: y })} />

															<Modal {...selectContractPreference({ idx: y })} />
														</div>
													))}
												</div>
											</div>
										)}

										<div className="section-003 mt-3">
											<div className="con-title">
												<h2>Find Jobs in these Categories</h2>
											</div>

											<div className="con-body">
												<ul>
													<li>
														<Link to="/dashboard">React JS</Link>
													</li>
													<li>
														<Link to="/dashboard">React Native</Link>
													</li>
													<li>
														<Link to="/dashboard">C++</Link>
													</li>
													<li>
														<Link to="/dashboard">Front-end</Link>
													</li>
												</ul>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{message.length > 0 && <Message messages={message} />}
		</div>
	);
}

export default Talent;
