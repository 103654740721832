// src/ChatList.js
import React, { useState } from 'react';
import service from 'services';

const ChatList = ({ onSelectChat }) => {
	const [selectedChatId, setSelectedChatId] = useState(null);
	const chats = [
		{
			id: 1,
			name: 'Alice',
			messages: [
				{ id: 1, text: 'Hello, how are you?', sender: 'received', blocked: false },
				{ id: 2, text: 'I am good, thanks! How about you?', sender: 'sent', blocked: true },
				{ id: 3, text: 'I am doing well too!', sender: 'received', blocked: false },
				{ id: 4, text: 'Glad to hear that!', sender: 'sent', blocked: false },
			]
		},
		{
			id: 2,
			name: 'Bob',
			messages: [
				{ id: 5, text: 'Hi there!', sender: 'received', blocked: false },
				{ id: 6, text: 'Hey! How’s it going?', sender: 'sent', blocked: true },
				{ id: 7, text: 'Pretty good, you?', sender: 'received', blocked: false },
				{ id: 8, text: 'Same here!', sender: 'sent', blocked: false },
			]
		},
		// Add more chats with messages as needed
	];


	const handleChatClick = (chat) => {
		setSelectedChatId(chat.id);
		onSelectChat(chat);
	};

	return (
		<aside style={{ backgroundColor: '#ECECEC', padding: '20px', borderRadius: '15px' }}>
			<div>
				<h2 style={{ fontWeight: '800', paddingBottom: '30px' }}>Conversations ({chats.length})</h2>
				<input type="text" placeholder="Search Talents" className='shadow' style={{ padding: '15px', borderRadius: '10px', outline: 'none', border: 'none' }} />
			</div>
			<div className="list-group h-100 overflow-auto d-flex flex-column gap-3 my-3" style={{ maxHeight: '500px', height: '500px' }}>
				{chats.map((chat) => (
					<article
						key={chat.id}
						className="list-group-item list-group-item-action d-flex align-items-center gap-2 p-2"
						onClick={() => handleChatClick(chat)}
						style={{
							cursor: 'pointer',
							backgroundColor: 'white',
							borderRadius: '10px',
							border: selectedChatId === chat.id ? '3px solid #F0CDB3' : 'none', // Add border to selected chat
						}}
					>
						<figure className="m-0" style={{ width: '30%', border: '5px solid #FFB700' }}>
							<img src="https://t3.ftcdn.net/jpg/06/33/54/78/240_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg" alt="placeholder" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
						</figure>
						<div className="flex-grow-1" style={{ padding: '0 2px' }}>
							<div className="mb-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
								<p style={{ fontWeight: '500', fontSize: '14px', color: '#2E2E30' }}>{service.truncateText(chat.name, 16)}</p>
								<p style={{ color: '#828283', fontWeight: '600' }}>{'12:00pm'}</p>
							</div>
							<p className="mb-1" style={{ color: '#D2671A', fontWeight: '600' }}>{service.truncateText('Back-end Developer', 16)}</p>
							<p className="mb-0" style={{ color: '#969697', fontWeight: '400' }}>
								{service.truncateText('Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, possimus. Alias asperiores repellendus suscipit, voluptas enim amet doloremque laudantium natus voluptate repellat, iusto facilis recusandae!', 16)}
							</p>
						</div>
					</article>
				))}
			</div>
		</aside>
	);
};

export default ChatList;
