import React, { useEffect, useRef, useState } from 'react';
import ChatList from './Chatlist';
import ChatWindow from './ChatWindow';
import service from 'services';
import Navbar from 'views/_shared/navbar/Navbar';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp';
import Footer from 'views/_shared/footer/Footer';

const Conversations = () => {
	service.setPageTitle('Conversations');
	const top = useRef(null);
	useEffect(() => service.goTo(top), []);
	const [selectedChat, setSelectedChat] = useState(null);

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#F6F6F6' }}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height" style={{ padding: '2%', width: '100%', }}>
				<div className="con-section p-1 row gap-3">
					<div className={`col-md-5 col-lg-4 p-0 ${selectedChat ? 'd-none d-md-block' : ''}`}>
					<ChatList onSelectChat={setSelectedChat} />
				</div>
				<div className={`col-md-6 col-lg-7 p-0 ${selectedChat ? '' : 'd-md-block'}`}>
				<ChatWindow chat={selectedChat} onBack={() => setSelectedChat(null)} />
			</div>
		</div>
			</div >
	<Footer />
		</div >

	);
};

export default Conversations;