import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import './css/SectionE.style.sass';

function SectionE() {
	const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	function doHandleSelectorA({ currentTarget }) {
		document.querySelectorAll(`.selector.section-a .dot`).forEach((x) => x.classList.remove('active'));
		currentTarget.querySelector('.selector .dot').classList.add('active');
	}

	function doHandleSelectorB({ currentTarget }) {
		document.querySelectorAll(`.selector.section-b .dot`).forEach((x) => x.classList.remove('active'));
		currentTarget.querySelector('.selector .dot').classList.add('active');
	}

	function doHandleSelectorC({ currentTarget }) {
		document.querySelectorAll(`.selector.section-c .dot`).forEach((x) => x.classList.remove('active'));
		currentTarget.querySelector('.selector .dot').classList.add('active');
	}

	function doHandleSelectorD({ currentTarget }) {
		document.querySelectorAll(`.selector.section-d .dot`).forEach((x) => x.classList.remove('active'));
		currentTarget.querySelector('.selector .dot').classList.add('active');
	}

	function doSubmit() {
		navigate('/dashboard');
	}

	return (
		<div id="Onboarding_Talent_SectionE_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<p className="mb-3 d-flex align-items-center gap-3">Basic Info</p>

							<h2 className="display">Let's get to know you better!</h2>

							<p className="mb-3">Let's dig a little deeper!</p>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="90" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01">
				<div className="con-form">
					<div className="form">
						<form>
							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
								<img src="/img/svg/officebox.svg" alt="suitcase" className='svg' />
									<h2>Are you actively looking looking for a job?</h2>
								</div>

								<div className="con-selection-block d-lg-flex align-items-start justify-content-between gap-4 mb-4">
									<div id="section-a" className="selection-block d-flex align-items-center" onClick={doHandleSelectorA}>
										<div className="selector section-a">
											<div className="radio-button">
												<div className={`dot`}></div>
											</div>
										</div>

										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className='svg' style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg valid"></div>
												</div>
												<h2>Ready to Interview</h2>
											</div>
											<div>
												<p>I'm available for a job and available for an interview in the next 30 days</p>
											</div>
										</div>
									</div>
								</div>

								<div className="con-selection-block d-lg-flex align-items-start justify-content-between gap-4 mb-4">
									<div className="selection-block d-flex align-items-center" onClick={doHandleSelectorA}>
										<div className="selector section-a">
											<div className="radio-button">
												<div className={`dot`}></div>
											</div>
										</div>

										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className='svg' style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg warn"></div>
												</div>
												<h2>Open to Offers</h2>
											</div>
											<div>
												<p>I'm available for a job and available for an interview in the next 30 days</p>
											</div>
										</div>
									</div>
								</div>

								<div className="con-selection-block d-lg-flex align-items-start justify-content-between gap-4 mb-4">
									<div className="selection-block d-flex align-items-center" onClick={doHandleSelectorA}>
										<div className="selector section-a">
											<div className="radio-button">
												<div className={`dot`}></div>
											</div>
										</div>

										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className='svg' style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg"></div>
												</div>
												<h2>Unavailable for Jobs</h2>
											</div>
											<div>
												<p>I'm available for a job and available for an interview in the next 30 days</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-user-check"></i>
									<h2>Are you interested in working full time (8hrs/day, 40 hrs/week)?</h2>
								</div>

								<div className="radio-button-option section-b">
									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorB}>
										<div className="selector section-b">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Yes</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorB}>
										<div className="selector section-b">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>No, only part-time</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorB}>
										<div className="selector section-b">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>I can start part-time immediately and then switch to full-time within a month</h2>
									</div>
								</div>
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-user-check"></i>
									<h2>What is your notice period for resigning from your current job and starting your new job?</h2>
								</div>

								<div className="radio-button-option section-c">
									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorC}>
										<div className="selector section-c">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Immediately</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorC}>
										<div className="selector section-c">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2 className="d-flex align-items-center gap-2">
											In <input type="number" placeholder="eg: 2" min={1} /> week after I get the offer
										</h2>
									</div>
								</div>
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-user-check"></i>
									<h2>How did you first hear about us?</h2>
								</div>

								<div className="radio-button-option section-d">
									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>I received an email from {service.brand.name}</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>I found {service.brand.name} when searching for a job on Google</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Facebook ads</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Youtube ads</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Linkedin/ Quora/ Twitter/ Reddit ads</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Word of mouth</h2>
									</div>

									<div className="d-flex align-items-center gap-3 mb-3" onClick={doHandleSelectorD}>
										<div className="selector section-d">
											<div className="radio-button">
												<div className="dot"></div>
											</div>
										</div>

										<h2>Other</h2>
									</div>
								</div>
							</div>

							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>One Step back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<h1>Finish</h1>
												<i className="fa-solid fa-arrow-right"></i>
											</>
										}
										onClick={doSubmit}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionE;
