const { default: Button } = require("components/buttons/Button");
const { doHandleLogout } = require("views/dashboard/logout/Logout");

const style = {
  "con-notify": {
    cursor: "pointer",
  },
  i: {
    fontSize: "1.6rem",
  },
};

export const navbarComp = {
  rightContent: (
    <div className="d-flex align-items-center gap-3">
      <Button
        text={<h2>Logout</h2>}
        small
        redirect="/login"
        onClick={doHandleLogout}
      />
      <div className="con-notify" style={style["con-notify"]}>
        <i className="fa-solid fa-bell" style={style.i}></i>
      </div>
    </div>
  ),
};
