import BreadCrumb from 'components/bread-crumb/BreadCrumb.jsx';
import JobCard from 'components/cards/job-card/JobCard';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination.jsx';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp.jsx';
import './css/AppliedJobs.style.sass';
import Button from 'components/buttons/Button';
import { useNavigate } from 'react-router-dom';

function AppliedJobs() {
	service.setPageTitle('My Applied Jobs');
	const userType = service.getUserType();
	const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [isLoading, setIsLoading] = useState(true);
	const [appliedJobs, setAppliedJobs] = useState([]);

	function fetchApplied() {
		setIsLoading(true);

		function onFinal() {
			setIsLoading(false);
		}

		if (userType === 'talent') {
			api.talents.jobs
				.appliedJobs()
				.then((res) => setAppliedJobs(res?.data?.result))
				.catch(console.error)
				.finally(onFinal);
		} else {
			api.freelancers.jobs
				.appliedJobs()
				.then((res) => setAppliedJobs(res?.data?.result))
				.catch(console.error)
				.finally(onFinal);
		}
	}

	useEffect(fetchApplied, [userType]);
	// console.log(jobs);

	const breadCrumbProp = [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'Applied Jobs',
			link: '',
		},
	];

	return (
		<div id="Applied_Jobs_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap">
						<div className="con-header mx-4 mt-4">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Applied Jobs</h2>

							<div className="body">
								{!isLoading &&
									appliedJobs?.length > 0 &&
									appliedJobs?.map((job, idx) => {
										return <JobCard key={idx} data={job.job} callback={fetchApplied} />;
									})}
							</div>
							{!isLoading && appliedJobs?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src="/img/oops.png" alt="oops" className="img-fluid w-25" />
									<h3 className="my-2">Oops!</h3>
									<p>You haven't applied for any job</p>
									<Button primaryNull text={'Apply For A Job'} onClick={() => navigate('/dashboard/jobs/recommended-jobs')} />
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>

						{appliedJobs?.length > 9 && (
							<div className="footer">
								<IndexPagination />
							</div>
						)}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default AppliedJobs;
