import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import Button from 'components/buttons/Button';
import Selector from 'components/buttons/selector/Selector';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp';
import './css/ProjectDetails.style.sass';
import api from 'services/api';

function ProjectDetails() {
	service.setPageTitle('Project');
	const userType = service.getUserType();

	const top = useRef(undefined);
	const [project, setProject] = useState('');
	const { projectId } = useParams();
	const [talentId, setTalentId] = useState('')


	useEffect(() => service.goTo(top), []);

	useEffect(() => {
		function handleSuccess(response) {
			if (response) {
				setProject(response.data.result);
				console.log(response.data.result);
			}
		}
		if (userType === 'talent') {
			api.talents.projects
				.getSingleProject(projectId)
				.then(handleSuccess)
				.catch((err) => console.log(err));
		} else if (userType === 'freelancer') {
			api.talents.projects
				.getSingleProject(projectId)
				.then(handleSuccess)
				.catch((err) => console.log(err));
		} else {
			api.recruiter
				.getTalentsSingleProject(projectId)
				.then((res) => {
					setProject(res.data.result)
					setTalentId(res.data.result.talent)

				})
				.catch((err) => console.log(err));
		}
	}, [projectId, userType]);
	console.log(talentId)
	const breadCrumbProp = [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'Project',
			link: '',
		},
	];

	return (
		<div id="Project_Details_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="con-header">
						<BreadCrumb context={breadCrumbProp} />
					</div>

					<div className="con-header mb-4 mb-lg-5">
						<h1>{project.project_title}</h1>
					</div>

					<div className="outline-wrap">
						<div className="con-context">
							<div className="con-body">
								<div className="outline-wrap">
									<div>
										<h3 style={{ marginBottom: '32px', fontSize: '20px' }}>Images</h3>
										<div className="con-img d-flex flex-column flex-lg-row align-items-start gap-2 gap-lg-3 overflow-scroll justify-content-start mt-3" style={{ marginBottom: '32px' }}>
											{project?.project_images &&
												project.project_images.map((image) => {
													const link = image?.href_link;
													const name = image?.file_name;
													return (
														<div className="img" key={image._id}>
															<img src={link} alt={name} style={{ maxWidth: '500px', width: '450px' }} />
														</div>
													);
												})}
										</div>
									</div>

									<div>
										<h3 style={{ marginBottom: '32px', fontSize: '20px' }}>Videos</h3>
										<div className="con-img d-flex flex-column flex-lg-row align-items-center gap-2 gap-lg-3 mt-4" style={{ marginBottom: '32px' }}>
											{project?.project_videos &&
												project.project_videos.map((video) => {
													const link = video?.href_link;
													return (
														<div className="img" key={video._id}>
															<video controls style={{ width: '500px', height: '220px' }}>
																<source src={link} />
															</video>
														</div>
													);
												})}
										</div>
									</div>

									<div className="text-body my-4">
										<h3 className="" style={{ marginBottom: '32px', fontSize: '20px' }}>
											Description
										</h3>
										<p>{project.project_description}</p>
									</div>

									{/* skills */}
									<div className="con-selector">
										<div className="d-flex align-items-center gap-2">
											<i className="fa-solid fa-screwdriver-wrench"></i>
											<h2>Related Skills</h2>
										</div>

										<div className="my-4">{project?.project_skills && project.project_skills.map((x, y) => <Selector context={x} key={y} />)}</div>
									</div>
									{/* link */}
									<div className="con-selector">
										<div className="d-flex align-items-center gap-2">
											<i className="fa-solid fa-link"></i>
											<h2>Project Link</h2>
										</div>

										<div className="py-3">
											<a href={project?.project_link} target="_blank" rel="noreferrer">
												Here is My Project Link, you can click and check it out
											</a>
										</div>
									</div>
									{/* source files */}
									<div className="con-selector">
										<div className="d-flex align-items-center gap-2">
											<i className="fa-solid fa-file"></i>
											<h2>Source Files</h2>
										</div>

										<div className="m-2 row gap-2">
											{project?.project_source_files &&
												project.project_source_files.map((file) => {
													return (
														<div key={file._id} className="d-flex border border-2 border-dark py-4 px-3 mt-1 col-6 rounded-3" style={{ maxWidth: '350px' }}>
															<a href={file?.href_link} download={file?.file_name}>
																{file?.file_name}
															</a>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>



							<div className="con-footer d-flex align-items-center gap-3 mt-5">
								<Button text={<h2>Like</h2>} primaryNull />
								{
									userType !== 'recruiter' &&

									<Link to="/dashboard/profile">
										<Button
											text={
												<>
													<h2>View Profile</h2>
													<i className="fa-solid fa-arrow-right"></i>
												</>
											}
										/>
									</Link>
								}

							</div>

						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default ProjectDetails;
