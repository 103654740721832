import Tab from 'components/buttons/tabs/Tabs';
import JobCard from 'components/cards/job-card/JobCard';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import s from './css/FindTalent.module.sass';
import FilterModal from './modals/filter-modal/FilterModal';
import { navbarComp } from './navbar-component/navbar-comp';

function Search() {
	service.setPageTitle('Search');
	const top = useRef(undefined);
	const tabs = useRef(undefined);
	const [query] = useSearchParams();
	const navigate = useNavigate();
	const ACTIVE_ROLE = service.getUserType();

	// New state to store filter options
	const [filterOptions, setFilterOptions] = useState({});
	// Function to handle filter changes and update filterOptions
	function handleFilterChange(filters) {
		setFilterOptions(filters);
	}

	useEffect(() => service.goTo(top), []);

	const [activeTab, setActiveTab] = useState('');
	const [clickedTab, setClickedTab] = useState('');
	const [input, setInput] = useState('');
	// eslint-disable-next-line
	const [fullTimeDecision, setFullTimeDecision] = useState('full-time');

	const [isLoading, setIsLoading] = useState(true);
	const [searchResults, setSearchResults] = useState([]);
	const [jobTitles, setJobTitles] = useState([]);

	function handleSearch(evt) {
		evt?.preventDefault();
		setInput(query.get('search'));

		function successHandler(response) {
			const result = response?.data?.result;
			if (ACTIVE_ROLE === service.TALENT) {
				setSearchResults(result.filter(job => job.isJobApproved !== false))
			} else {
				setSearchResults(result);
			}
		}

		function onFinal() {
			setIsLoading(false);
		}

		// Extract the filter options from state
		const { gender, contract_type, country, state, job_type, status } = filterOptions;

		const filters = {};
		if (gender) {
			filters.gender = gender;
		}

		if (contract_type) {
			filters.contract_type = contract_type;
		}

		if (job_type) {
			filters.job_type = job_type;
		}

		if (country) {
			filters.country = country;
		}


		if (state) {
			filters.state = state;
		}

		if (status) {
			filters.status = status;
		}

		if (ACTIVE_ROLE === service.RECRUITER) {
			api.recruiter
				.findTalents({ full_time_decision: query.get('type'), job_title: query.get('search'), ...filters })
				.then(successHandler)
				.catch(console.error)
				.finally(onFinal);
		} else if (ACTIVE_ROLE === service.TALENT) {
			api.talents.jobs
				.findJobs({ job_title: query.get('search'), status: 'published', ...filters })
				.then(successHandler)
				.catch(console.error)
				.finally(onFinal);
			api.talents.jobs.getJobTitles().then(res => { setJobTitles(res.data?.result) }).catch(res => console.log(res))
		}
	}

	function doSubmit(evt) {
		evt?.preventDefault();
		setIsLoading(true);
		navigate({ pathname: '/dashboard/search', search: `type=${fullTimeDecision}&search=${input}` });
	}

	const prev = () => (tabs.current.scrollLeft -= 400);
	const next = () => (tabs.current.scrollLeft += 400);

	useEffect(handleSearch, [query, ACTIVE_ROLE, filterOptions]);
	useEffect(() => {
		if (clickedTab !== '') {
			doSubmit();
		}
		// eslint-disable-next-line
	}, [clickedTab]);

	return (
		<div ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className={s['con-section']}>
					<form className={`${s.form} d-lg-flex align-items-center gap-3`} onSubmit={doSubmit}>
						<div className={s.button}>
							<p>&nbsp;&nbsp;&nbsp;{query.get('type') || `Search`} &nbsp;&nbsp;</p>
						</div>
						<div className={`${s['input-area']} my-3 my-lg-0`}>
							<input type="text" placeholder="Search for talents by job title... eg. Designer" value={input} onChange={(e) => setInput(e.target.value)} />
						</div>
						<button type="submit" className={s['arrow-btn']} onClick={doSubmit}>
							<i className="fa-solid fa-arrow-right"></i>
						</button>
					</form>

					<div className={s.context}>
						<div className={s['header']}>
							{ACTIVE_ROLE === service.TALENT && <div className={`${s['filter-btn']} d-flex align-items-center justify-content-evenly gap-1`}>
								<div>
									<div className={`${s['arrow-btn']}`} onClick={prev}>
										<i className="fa-solid fa-arrow-left"></i>
									</div>
								</div>
								<span className="d-flex align-items-center gap-1" ref={tabs}>
									{jobTitles?.map((x, y) => (
										<Tab key={y} primaryOutline context={x.title}
											callback={() => {
												setActiveTab(y);
												setInput(x.title);
												setClickedTab(x.title);
											}}
											activeTab={activeTab === y} />
									))}
								</span>
								<div>
									<div className={`${s['arrow-btn']} ${s.outline}`} onClick={next}>
										<i className="fa-solid fa-arrow-right"></i>
									</div>
								</div>
							</div>}

							<div className={`${s['filter-sidebar']} d-lg-flex align-items-center justify-content-between my-4`}>
								<div className={s['sidebar-btn']} data-bs-toggle="modal" data-bs-target="#filter-modal">
									<i className="fa-solid fa-sliders"></i>
									<h2>Filters</h2>
								</div>
								<div className="mt-4 mt-lg-0">
									<h1>Results for {query.get('search')}</h1>
								</div>
								<i></i>
							</div>
						</div>

						<div className={s.body}>
							<div className={s['con-card']}>
								<div className={s['cards-a']}>
									{ACTIVE_ROLE === service.TALENT &&
										!isLoading &&
										(searchResults && searchResults.length > 0
											? searchResults.map((job, idx) => {
												return <JobCard data={job} key={idx + 1} />;
											})
											: `No results for ${query.get('search')}`)}
								</div>

								<div className={s['cards-b']} style={{display:'flex', flexWrap:'wrap'}}>
									{ACTIVE_ROLE === service.RECRUITER &&
										!isLoading &&
										(searchResults && searchResults.length > 0
											? searchResults.map((result, y) => {
												return (
													<Link to={`/dashboard/talents-profile/${result?.id}`} key={y} >
														<div className={s.card} style={{ height: '270px', width:'200px' }}>
															<div className={s.header}>
																<p>{`CAND${y + 1}`}</p>
																<i className="fa-regular fa-user"></i>
															</div>

															<div className={s.body}>
																<div className={s.img}>
																	<img src={result?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />

																	{/* DO NOT REMOVE */}
																	{/* <img src={result?.profile_picture?.href_link} alt="head shot" /> */}
																</div>

																<div className={s.text}>
																	<div style={{ display: 'flex', gap: '5px' }}>
																		<h1 className='truncate-e' style={{ fontSize: '16px' }}>
																			{service.truncateText(result?.first_name,10)} {service.truncateText(result?.last_name,13)}
																		</h1>
																		<img src={result?.isProfileApproved ? "/img/svg/verified.svg" : '/img/svg/pending.svg'} alt="" style={{ width: '15px' }} />
																	</div>
																	<h3 className="my-2 truncate-e">{service.truncateText(result?.title || 'No title',10)}</h3>
																	<p className="d-flex align-items-center gap-2 truncate-e">
																		<i className="fa-solid fa-location-dot"></i>
																		{result?.location?.resident_state || `Lagos`}, {result?.location?.resident_country || `Nigeria`}
																	</p>
																</div>
															</div>
														</div>
													</Link>
												);
											})
											: `No results for ${query.get('search')}`)}
								</div>

								{isLoading && [1].map((x, y) => <div className={s['animated-bg']} key={y}></div>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />

			<FilterModal onFilterChange={handleFilterChange} />
		</div>
	);
}

export default Search;
