// FeedBackModal.jsx
import Button from 'components/buttons/Button';
import './css/Feedback.css';
import Loader from 'components/loader/Loader';

const FeedBackModal = ({ title, details, handleSubmit, option = false, placeHolderText, modalTitle, modalWidth, onCancel, isLoading, handleChange, subjects, Subject }) => {
	return (
		<div className=" s_modal   modal d-block  " data-bs-backdrop="static">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: modalWidth || `500px` }}>
				<div className="modal_content modal-content">
					<div className=" modal-header" style={{ padding: '7px' }}>
						<i className="fa-regular fa-user"></i>
						<h5 className="modal-title" style={{ fontSize: '10px' }}>{modalTitle || `modal title`}</h5>
						<i className="fa-solid fa-xmark" data-bs-dismiss="modal" onClick={onCancel}></i>
					</div>

					<div className="body ">
						{' '}
						<div className="feedBackModal-con flex-column flex-lg-row">
							<h2 className="my-3">{title}</h2>
							<form className="my-4">
								<select className="form-select py-3" name={'reason'} value={details.reason} onChange={handleChange}>
									<option value="">{Subject}</option>
									{subjects.map((option, idx) => (
										<option key={idx} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
								<div className="my-3">
									<textarea className="form-control" name={'description'} rows={10} value={details.description} onChange={handleChange} placeholder={placeHolderText}></textarea>
								</div>

								{option && (
									<div className="feedback_options d-flex align-items-center w-100">
										<h2>Types:</h2>
										<div>
											{['Compliments', 'Suggestions', 'Complaints', 'just another'].map((type, index) => (
												<div className="form-check form-check-inline" key={index}>
													<input className="form-check-input e_input" type="radio" name="type" id={`inlineRadio${index + 1}`} value={type} onChange={handleChange} />
													<label className="form-check-label" htmlFor={`inlineRadio${index + 1}`}>
														{type}
													</label>
												</div>
											))}
										</div>
									</div>
								)}

								<div className="footer d-flex flex-column flex-lg-row align-items-center gap-3 my-4 w-100">
									<Button text="Cancel" onClick={onCancel} primaryNull />

									<Button onClick={handleSubmit} disabled={isLoading} text={isLoading ? <Loader /> : 'Submit'} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeedBackModal;
