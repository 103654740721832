import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api/index.js';
import './css/SectionA.style.sass';



function SectionA({ callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const errorRef = useRef(undefined);
	const page = 1;
	const limit = 10;

	const [talent, setTalent] = useState('');
	const [jobTitles, setJobTitles] = useState([]);
	const [selectedTalent, setSelectedTalent] = useState([]);
	const [throwError, setThrowError] = useState(false);

	useEffect(() => {
		api.recruiter.getJobTitles(page, limit).then(res => setJobTitles(res.data?.result)).catch(res => console.log('main', res))
	}, [page, limit]);

	const removeActiveState = () => {
		document.querySelectorAll('#con-talent-box').forEach((x) => x.classList.remove('active'));
	};

	const doSelectTalent = ({ currentTarget: ctg }, index) => {
		if (!talent) {
			removeActiveState();
			setThrowError(false);

			if (selectedTalent.some((x) => x.id === index)) {
				ctg.classList.remove('active');

				setSelectedTalent(selectedTalent.filter((x) => x.id !== index));
			} else {
				ctg.classList.add('active');


				setSelectedTalent([{ title: jobTitles[index].title, id: index }]);
			}
		}
	};

	const handleTalentValue = ({ target: { value } }) => {
		if (!selectedTalent.length) {
			setThrowError(false);
			setTalent(value);
		}
	};

	const doSubmit = () => {
		if (!selectedTalent.length && !talent) {
			setThrowError(true);
			service.goTo(errorRef);
		} else {
			let body = selectedTalent[0]?.title || talent;
			callback(body);
		}
	};

	return (
		<div id="JobFlow_SectionA_Main_Container" ref={top}>
			<div className="section">
				<div className="con-header">
					<div className="container-01">
						<div className="header">
							<h2>Let's get you your Talent!</h2>
							<p className="mt-3">No need to hassle over the best for the job. We can get it for you. Right here, Right Now.</p>
						</div>
					</div>
				</div>

				<div className="con-context container-01" ref={errorRef}>
					<div className="my-5">
						<div className="context">
							<div className="title">
								<h2 style={{ color: `${throwError ? 'red' : ''}` }}>Select Job Title</h2>
							</div>

							<div className="con-talent mt-3 row">
								{jobTitles.map(({ title }, idx) => {
									return (
										<div className="talent d-flex d-lg-block align-items-center flex-column" key={idx}>
											<div className="con-box" id="con-talent-box" onClick={(e) => doSelectTalent(e, idx)}>

												<p className="caption truncate-text-e mt-2" style={{ textAlign: 'center' }}>{title}</p>

												<div className="con-checkbox">
													<div className="checkbox"></div>
												</div>
											</div>

										</div>


									);
								})}
							</div>
						</div>

						<div className="con-search">
							<p className="title">Title not found? Enter your preference</p>
							<div className="con-input">
								<input type="text" placeholder="Add Title" value={talent} onChange={handleTalentValue} disabled={!!selectedTalent.length} />
							</div>
						</div>

						<div>
							<Button
								text={
									<>
										<h1>Next</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								onClick={doSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;
