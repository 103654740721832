import './css/SectionB.style.sass';
import api from 'services/api';
import { useEffect, useState } from 'react';
import Carousel from 'components/carousel';

function SectionB() {
	const [talents, setTalents] = useState([]);

	useEffect(() => {
		api.contactUs
			.getusers()
			.then((res) => {
				setTalents(res.data.result.slice(1, 4));
			})
			.catch((err) => console.log(err));
	}, []);
	const projectpic = [
		'https://images.pexels.com/photos/4126743/pexels-photo-4126743.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		'https://images.pexels.com/photos/5668483/pexels-photo-5668483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		'https://images.pexels.com/photos/4008338/pexels-photo-4008338.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	];
	const slides = talents.map((user, userIndex) => (
		<div className="card-01" key={userIndex}>
			<div className="img">
				<img src={projectpic[userIndex] || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
			</div>

			<div className="context d-flex align-items-center justify-content-between">
				<div className="con-user d-flex align-items-center gap-3">
					<div className="user-dp">
						<img src={user.profile_picture?.href_link || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
					</div>

					<div className="user-data">
						<h2>{user.last_name + ' ' + user.first_name}</h2>
						<p>{user.job_title || 'Talent'}</p>
					</div>
				</div>

				<div className="con-rating">
					<div className="rating">
						<p>Ratings</p>
						<h2>{user.rating}</h2>
					</div>
				</div>
			</div>
		</div>
	));

	// const slider = [
	// 	<div className="card-01 ">
	// 		<div className="img">
	// 			<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 		</div>

	// 		<div className="context d-flex align-items-center justify-content-between">
	// 			<div className="con-user d-flex align-items-center gap-3">
	// 				<div className="user-dp">
	// 					<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 				</div>

	// 				<div className="user-data">
	// 					<h2>Opertop</h2>
	// 					<p>'Talent'</p>
	// 				</div>
	// 			</div>

	// 			<div className="con-rating">
	// 				<div className="rating">
	// 					<p>Ratings</p>
	// 					<h2>20.0</h2>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>,
	// 	<div className="card-01 ">
	// 		<div className="img">
	// 			<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 		</div>

	// 		<div className="context d-flex align-items-center justify-content-between">
	// 			<div className="con-user d-flex align-items-center gap-3">
	// 				<div className="user-dp">
	// 					<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 				</div>

	// 				<div className="user-data">
	// 					<h2>Opertop</h2>
	// 					<p>'Talent'</p>
	// 				</div>
	// 			</div>

	// 			<div className="con-rating">
	// 				<div className="rating">
	// 					<p>Ratings</p>
	// 					<h2>20.0</h2>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>,
	// 	<div className="card-01 ">
	// 		<div className="img">
	// 			<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 		</div>

	// 		<div className="context d-flex align-items-center justify-content-between">
	// 			<div className="con-user d-flex align-items-center gap-3">
	// 				<div className="user-dp">
	// 					<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 				</div>

	// 				<div className="user-data">
	// 					<h2>Opertop</h2>
	// 					<p>'Talent'</p>
	// 				</div>
	// 			</div>

	// 			<div className="con-rating">
	// 				<div className="rating">
	// 					<p>Ratings</p>
	// 					<h2>20.0</h2>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>,
	// 	<div className="card-01 ">
	// 		<div className="img">
	// 			<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 		</div>

	// 		<div className="context d-flex align-items-center justify-content-between">
	// 			<div className="con-user d-flex align-items-center gap-3">
	// 				<div className="user-dp">
	// 					<img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png'} alt="" />
	// 				</div>

	// 				<div className="user-data">
	// 					<h2>Opertop</h2>
	// 					<p>'Talent'</p>
	// 				</div>
	// 			</div>

	// 			<div className="con-rating">
	// 				<div className="rating">
	// 					<p>Ratings</p>
	// 					<h2>20.0</h2>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>,
	// ];

	return (
		<div id="SectionB_Main_Container">
			<div className="con-section">
				<div className="con-layer">
					<div className="con-overlay d-lg-flex align-items-center justify-content-center">
						<img src="img/Frame 7.png" alt="" />
						<img src="img/Frame 7 (1).png" alt="" />
						<img src="img/Frame 8.png" alt="" />
					</div>

					<div className="con-context">
						<div className="container-01">
							<div className="con-header">
								<h1>We have the best talents</h1>
								<p>We have a system in place that picks out the best talents just for you</p>
							</div>

							<div className="con-carousel">
								<div className="carousel d-lg-flex align-items-center gap-4">
									<Carousel slides={slides} showIndicator={true} itemsToShow={3} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionB;
