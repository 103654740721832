import './css/SectionD.style.sass';

function SectionD({ content, backgroundColor, flexSwitch, title, maxWidth, secondTitle }) {
	return (
		<div id="SectionD_Main_Container" style={{ background: `${backgroundColor}` }}>
			<div className="con-section py-5 py-lg-0">
				<div className="container-01">
					<div className={`d-lg-flex align-items-center justify-content-center gap-5 ${flexSwitch}`}>
						<div className="con-left">
						<img src="img/bottom.png" alt="" />
						</div>

						<div className="con-right pt-4 pt-lg-0" style={{ maxWidth: `${maxWidth}` }}>
							{content}
							{secondTitle}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default SectionD;

<img src="img/bottom.png" alt="" />

