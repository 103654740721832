import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionB.style.sass';
import api from 'services/api/index.js';
import classNames from '../../../../../utils/conditional-css-classes/classNames';

function SectionB({ prev, callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [description, setDescription] = useState('');
	const [descriptionError, setDescriptionError] = useState('');
	const [skills, setSkills] = useState([{ name: '', experience_years: '', competency: '' }]);
	const [suggestedSkillsA, setSuggestedSkillsA] = useState([]);
	const [suggestedSkillsB, setSuggestedSkillsB] = useState([]);

	const [activeSuggest, setActiveSuggest] = useState(false);
	const [suggestId, setSuggestId] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleOnChangeDynamic(event, idx, callback = () => {}, ...rest) {
		const name = event?.target.name ?? rest[0];
		const value = event?.target.value ?? rest[1];

		const data = [...skills];

		data[idx][name] = value;
		setSkills(data);

		clearError(idx, name);
		callback(event);
	}

	function handleOnChange(event) {
		const value = event.target.value.trim();
		setDescription(value);
		setDescriptionError('');
	}

	function addField() {
		const newField = { name: '', experience_years: '', competency: '' };
		setSkills([...skills, newField]);
	}

	function removeField(index) {
		const data = skills.filter((x, y) => y !== index);
		setSkills(data);
	}

	function hasErrors() {
		const exists = skills.some(validateArray);
		if (!description) {
			return validate();
		} else return exists;
	}

	function validate() {
		const formElement = document.forms[0].elements;
		formElement[`description`]?.focus();
		formElement[`description`]?.scrollIntoView({ block: 'center' });
		setDescriptionError('description field is required');
		return true;
	}

	function validateArray(object, idx) {
		for (const key in object) {
			const formElement = document.forms[0].elements;
			if (!object[key]) {
				if (skills.length === 1) {
					formElement[key]?.focus();
					formElement[key]?.scrollIntoView({ block: 'center' });
					formElement[key].classList?.add('error');
				} else {
					formElement[key][idx]?.focus();
					formElement[key][idx]?.scrollIntoView({ block: 'center' });
					formElement[key][idx]?.classList.add('error');
				}

				return true;
			}
		}
	}

	function doSubmit() {
		if (!hasErrors()) {
			const skillNames = skills.map((skill) => skill.name);
			setIsSubmitting(true);

			// Assuming createSkill accepts a single skill name per time, so we make multiple requests simultaneously.
			Promise.all(skillNames.map((skillName) => api.admin.skills.createSkill({ name: skillName })))
				.catch(console.warn)
				.finally(() => callback({ required_skills: skills, description }));
		}
	}

	function fetchSkills() {
		function successHandler(res) {
			const result = res?.data?.result;
			setSuggestedSkillsA(result);
		}

		api.admin.skills.getAllSkills().then(successHandler).catch(console.error);
	}

	useEffect(fetchSkills, [skills]);

	function activateSuggest(evt) {
		setActiveSuggest(true);
		setSuggestedSkillsB(suggestedSkillsA.filter((skill) => skill.name.toLowerCase().includes(evt.target.value.toLowerCase())));
	}

	function deactivateSuggest() {
		setSuggestId(-1);
		setActiveSuggest(false);
	}

	function returnSuggestId(id) {
		setSuggestId(id);
	}

	function clearError(idx, name = 'name') {
		if (skills.length === 1) {
			document.forms[0].elements[name].classList.remove('error');
		} else {
			document.forms[0].elements[name][idx].classList.remove('error');
		}
	}

	return (
		<div id="JobFlow_SectionB_Main_Container" ref={top}>
			<div className="section">
				<div className="container-01">
					<div className="con-title">
						<h1>What do you require for this Opportunity</h1>

						<div className="d-lg-flex align-items-center gap-3">
							<p>It's great to know what is expected, wouldn't you agree</p>
							<div className="img d-none d-lg-block">
								<img src="/img/twemoji_beaming-face-with-smiling-eyes.png" alt="" />
							</div>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form>
								<div>
									<div className="con-title">
										<img src="/img/svg/officebox.svg" alt="case" className="svg" />
										<h2>About Job</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-lg-flex justify-content-between">
												<label>Job Description</label>
												<label className="error-msg col-12 col-lg-7">{descriptionError}</label>
											</div>

											<div className="d-flex">
												<div className="col-12 col-lg-8">
													<textarea placeholder="Enter Job Description" name="description" onChange={handleOnChange}></textarea>
													<p className="con-warn align-items-center gap-3">
														<i className="fa-solid fa-circle-exclamation"></i>
														Not more than 10000 words
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="mb-5">
									<div className="con-title">
										<img src="/img/svg/toolkit.svg" alt="case" className="svg" />
										<p>Skills and Competence</p>
									</div>

									{skills.length > 0 && (
										<div className="con-heading row mb-3 d-none d-lg-flex">
											<p className="col-3">Skills</p>
											<p className="col-3">Years of Professional Experience</p>
											<p className="col-3">Competency</p>
											<p className="col-3"></p>
										</div>
									)}

									<div className="con-input">
										{skills.length > 0 &&
											skills.map((skill, idx) => (
												<div className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-between gap-lg-3" key={idx}>
													<div className="control-form entry">
														<label className="d-block d-lg-none">Skill</label>
														<input name="name" placeholder={`Add Skill ${idx + 1}`} onChange={(e) => handleOnChangeDynamic(e, idx, activateSuggest)} onFocus={() => returnSuggestId(idx)} value={skills[idx]?.name} />

														<div className={`con-suggest ${classNames(activeSuggest && suggestId === idx && 'active')}`}>
															<ul className="suggest">
																{suggestedSkillsB.map((x, y) => (
																	<li key={y} onClick={(e) => handleOnChangeDynamic(null, idx, deactivateSuggest, 'name', x.name)}>
																		{x.name}
																	</li>
																))}
															</ul>
														</div>
													</div>

													<div className="control-form">
														<label className="d-block d-lg-none">Years of Professional Experience</label>
														<input placeholder={`Experience years`} type={'number'} name="experience_years" onChange={(e) => handleOnChangeDynamic(e, idx)} min={1} onFocus={deactivateSuggest} />
													</div>

													<div className="control-form">
														<label className="d-block d-lg-none">Competency</label>
														<select name="competency" onChange={(e) => handleOnChangeDynamic(e, idx)} onFocus={deactivateSuggest}>
															<option value="">Select Competency</option>
															<option value={1}>Beginner</option>
															<option value={2}>Intermediate</option>
															<option value={3}>Advanced</option>
															<option value={4}>Expert</option>
														</select>
													</div>

													<div className="control-form remove-icon">
														<i className="fa-solid fa-trash-can" onClick={() => removeField(idx)}></i>
													</div>
												</div>
											))}

										<Button
											text={
												<>
													<i className="fa-solid fa-plus"></i>
													<h2>Add New Skill</h2>
												</>
											}
											onClick={addField}
										/>
									</div>
								</div>

								<div className="d-flex flex-column flex-lg-row gap-3">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back (Your Info)</h1>
											</>
										}
										primaryNull
										onClick={prev}
										disabled={isSubmitting}
									/>

									<Button
										text={
											<>
												<h1>Next</h1>
												<i className="fa-solid fa-arrow-right"></i>
											</>
										}
										disabled={isSubmitting}
										onClick={doSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionB;
