import Message from 'components/message-api/Message';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import classNames from 'utils/conditional-css-classes/classNames';
import style from './css/JobCard.module.sass';
import Button from 'components/buttons/Button';

function JobCard({ box, data, tag, preview, callback }) {
	const ACTIVE_ROLE = service.getUserType();
	const location = useLocation();
	const navigate = useNavigate()

	const currentPageIndex = window.location.href.split('/').length;
	const isSavedPage = window.location.href.split('/')[currentPageIndex - 1] === 'saved-jobs';

	const [message, setMessage] = useState([]);

	function toggleSave() {
		function successHandler() {
			return [
				() => {
					setMessage([{ text: 'Job saved successfully' }]);
					clearMessages();
				},
				() => callback(),
			];
		}
		function errorHandler(response) {
			const result = response.data.result;
			setMessage([{ text: result }]);
			clearMessages();
		}

		!isSavedPage && api.talents.jobs.addToBookmark(data?._id).then(successHandler()[0]).catch(errorHandler);
		isSavedPage && api.talents.jobs.removeFromBookmark(data?._id).then(successHandler()[1]).catch(errorHandler);
	}

	function clearMessages() {
		function clear() {
			setMessage([]);
		}

		setTimeout(clear, service.errorMilliSecond);
	}

	return (
		<div className={style['card-job']}>
			<div className={`${style.header} d-flex align-items-center justify-content-between ${classNames(box && style['has-box'])}`}>
				<div className={`d-flex align-items-center ${classNames(!box && `gap-1`)}`}>
					{[1, 2, 3].map((x, y) => (
						<i className={`${classNames(box && `${style[`box-${y + 1}`]}`)} ${style.box}`} key={y}></i>
					))}
				</div>
				<i className="fa-solid fa-xmark"></i>
			</div>

			<div className={`${style.context} d-flex align-items-start`}>
				<div className={`${style['con-class']} d-flex justify-content-between`}>
					<div className={style['class-1']} style={{ width: '90%' }}>
						<h2 title={data?.title} className="d-flex align-items-center gap-3">
							{data?.title ? service.truncateText(data?.title) : `job title`} <i>{data?.job_type || `job type`}</i>
						</h2>
						<h3 className="mt-2 mb-3" title={data?.company_name}>
							{data?.company_name ? service.truncateText(data?.company_name) : `company name`}
						</h3>
						<h4 className="mb-3" title={`${data?.location?.state} ${data?.location?.country}`}>
							{data?.location?.state ? service.truncateText(service.titleCase(data?.location.state)) : `state`}, {data?.location?.country ? service.truncateText(service.titleCase(data?.location.country)) : `country`}
						</h4>
						<p>{data?.currency}{data?.expected_pay?.toLocaleString() || `expected pay`}/yr</p>
					</div>

					<div className={`${style['class-2']} d-flex flex-column align-items-end justify-content-between`} style={{ width: '10%' }}>
						<i onClick={toggleSave}>{ACTIVE_ROLE !== service.RECRUITER && <i className="fa-regular fa-bookmark"></i>}</i>

						{preview ? (
							<div className={`fa-solid fa-arrow-right ${style['arrow-btn']} ${style.outline}`} data-bs-toggle="modal" data-bs-target={`#modal-job-preview-${tag}`}>
								<i className={``}></i>
							</div>
						) : (
							<>
								{data?._id && (
									<Link to={`/dashboard/jobs/${data?._id}`} className={``}>
										<div className={`fa-solid fa-arrow-right ${style['arrow-btn']} ${style.outline}`}>
											<i className={``}></i>
										</div>
									</Link>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			{location.pathname === '/dashboard/jobs/applied' && (
				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mb-2 mx-2">
					<Button text={<h2>Details</h2>} primaryNull onClick={()=>navigate(`/dashboard/jobs/${data._id}`)}/>
					<Button text={
						<>
							<i class="fa-solid fa-user-group"></i>
							<p style={{fontSize:'16px'}}>View Applicants</p>
						</>
					} onClick={()=>navigate(`/dashboard/jobs/applicants/${data._id}`)}/>

				</div>
			)}

			{message.length > 0 && <Message messages={message} />}
		</div>
	);
}

JobCard.defaultProps = {
	callback: () => { },
};

export default JobCard;
