import Button from 'components/buttons/Button';
import Tab from 'components/buttons/tabs/Tabs';
import Modal from 'components/modals/Modal';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import './css/SectionC.style.sass';
import { handleModal } from './modal/Modal';

function SectionC(props) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [jobType, setJobType] = useState(0);
	const [contractType, setContractType] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [form, setForm] = useState({
		country: '',
		state: '',
		required_years_of_experience: '',
		expected_pay: '',
		job_email: '',
		receive_talent_by: '',
		job_type: '',
		contract_type: '',
	});

	const jobTypeEnum = ['remote', 'hybrid', 'onsite'];
	const contractTypeEnum = ['full-time', 'part-time', 'contract'];

	const handleOnChange = (e) => {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		setForm({ ...form, [`${attribute}`]: value });
	};

	const configFields = (form) => {
		form.job_type = jobTypeEnum[jobType];
		form.contract_type = contractTypeEnum[contractType];

		const postBody = { ...form };

		postBody.required_years_of_experience = Number(postBody.required_years_of_experience);
		postBody.expected_pay = Number(postBody.expected_pay);
		postBody.location = { country: postBody.country, state: postBody.state };

		delete postBody.country;
		delete postBody.state;

		return postBody;
	};

	const doSubmit = (callback, status = 'drafted') => {
		form.status = status;
		const postBody = configFields(form);

		setIsLoading(true);

		function successHandler(response) {
			setIsLoading(false);
			callback();
		}

		function errorHandler(response) {
			setIsLoading(false);
			console.error(response);
		}

		api.recruiter.updateJob(props.context?._id, postBody).then(successHandler).catch(errorHandler);
	};

	const callback1 = () => {
		navigate(`/dashboard/jobs/${props.context?._id}`);
	};

	const callback2 = () => {
		navigate(`/dashboard`);
	};

	const callback3 = () => {
		navigate('/dashboard/search');
	};

	function getContext() {
		function config() {
			const jobTypeIndex = jobTypeEnum.findIndex((job_type) => job_type === props?.context?.job_type);
			const contractTypeIndex = contractTypeEnum.findIndex((contract_type) => contract_type === props?.context?.contract_type);

			return [jobTypeIndex, contractTypeIndex];
		}

		setJobType(config()[0]);
		setContractType(config()[1]);

		setForm({
			...form,
			country: props?.context?.location?.country,
			state: props?.context?.location?.state,
			required_years_of_experience: props?.context?.required_years_of_experience,
			expected_pay: props?.context?.expected_pay,
			job_email: props?.context?.job_email,
			receive_talent_by: props?.context?.receive_talent_by,
			job_type: props?.context?.job_type,
			contract_type: props?.context?.contract_type,
		});
	}

	// eslint-disable-next-line
	useEffect(getContext, [props.context]);

	const modalProps = {
		modalWidth: '640px',
		modalTarget: 'post-opportunity-submitted',
		modalTitle: 'opportunity post review',
	};

	return (
		<div id="JobFlow_SectionC_Main_Container" ref={top}>
			<div className="section">
				<div className="container-01">
					<div className="con-title">
						<h1>We have 50+ Talents for this Opportunity</h1>

						<div className="d-lg-flex align-items-center gap-3">
							<p>Let's narrow it down, shall we?</p>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form id="section-c">
								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>What is the nature of the job?</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-lg-flex gap-2 mb-4">
												{jobTypeEnum.map((x, y) => (
													<Tab key={y} context={service.titleCase(x)} primaryOutline callback={() => setJobType(y)} activeTab={jobType === y} />
												))}
											</div>
										</div>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>Contract type</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-lg-flex gap-2 mb-4">
												{contractTypeEnum.map((x, y) => (
													<Tab key={y} context={service.titleCase(x)} primaryOutline callback={() => setContractType(y)} activeTab={contractType === y} />
												))}
											</div>
										</div>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>Location</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Country</label>
											</div>

											<select name="country" value={form.country} onChange={handleOnChange}>
												<option value="">Select country</option>
												<option value="nigeria">Nigeria</option>
											</select>
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>State</label>
											</div>

											<select name="state" value={form.state} onChange={handleOnChange}>
												<option value="">Select state</option>
												<option value="lagos">Lagos</option>
												<option value="abuja">Abuja</option>
											</select>
										</div>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>Work Experience</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Years of work experience</label>
											</div>

											<input type="number" placeholder="How many years of experience" name="required_years_of_experience" value={form.required_years_of_experience} onChange={handleOnChange} />
										</div>

										<i className="control-form"></i>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>What is the expected pay?</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Pay range</label>
											</div>

											<input type="number" placeholder="Enter expected pay" name="expected_pay" value={form.expected_pay} onChange={handleOnChange} />
										</div>

										<i className="control-form"></i>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>How would you like to receive your applicants?</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4 mb-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Receive talents by</label>
											</div>

											<select name="receive_talent_by" value={form.receive_talent_by} onChange={handleOnChange}>
												<option value="">Select how you'd receive talents</option>
												<option value="email">Email</option>
												<option value="phone">Phone</option>
											</select>
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Email Address</label>
											</div>

											<input type="text" placeholder="key@test.com" name="job_email" value={form.job_email} onChange={handleOnChange} />
										</div>
									</div>
								</div>

								<div className="control-form d-flex align-items-lg-center flex-column flex-lg-row gap-3">
									<Button text={'Save for Later'} secondaryNull disabled={isLoading} onClick={() => doSubmit(callback1)} />

									<Button text={<h1>Preview</h1>} primaryNull disabled={isLoading} {...handleModal()} />

									<Button
										text={
											<>
												<h1>Find your Talents now</h1>
												<i className="fa-solid fa-arrow-right"></i>
											</>
										}
										disabled={isLoading}
										onClick={() => doSubmit(callback3)}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>

				<Modal
					{...handleModal({
						...props.context,
						...form,
						callback: () => doSubmit(() => {}, 'published'),
					})}
				/>

				<Modal
					{...{
						...modalProps,
						modalBody: (
							<>
								<div className="section-d d-flex flex-column align-items-center gap-4">
									<div className="header mt-3">
										<h1>Opportunity Posted!</h1>
									</div>
									<div className="body d-flex flex-column align-items-center gap-4">
										<div className="img">
											<img src="/img/happy 1.png" alt="" />
										</div>
										<p>This Opportunity has been posted. Check your mails or Dashboard for updates.</p>
									</div>
									<div className="footer mb-4">
										<div data-bs-dismiss="modal">
											<Button
												text={
													<>
														<h2>Back to Dashboard</h2>
														<i className="fa-solid fa-arrow-right"></i>
													</>
												}
												onClick={callback2}
											/>
										</div>
									</div>
								</div>
							</>
						),
					}}
				/>
			</div>
		</div>
	);
}

export default SectionC;
