import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import ProjectCard from 'components/cards/project-card/ProjectCard';
import DotPagination from 'components/pagination/pagination-dot/DotPagination';
// import IndexPagination from 'components/pagination/pagination-index/IndexPagination';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp';
import './css/Profile.style.sass';
import Buttons from '../buttons/Buttons';

function TalentProfile() {
	service.setPageTitle('Profile');
	const {jobId, talentId } = useParams();
	const location = useLocation()

	const [user, setUser] = useState({});
	const [projectPreview, setProjectPreview] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	// eslint-disable-next-line
	const [loadingImage, setLoadingImage] = useState(false);
	// certificate upload

	useEffect(() => {
		if (user?.role === 'talent') {
			api.recruiter.getTalentsProject(talentId)
				.then((response) => {
					setProjectPreview(response.data.result.splice(0, 5));
				})
				.catch((error) => console.log('error', error))
				.finally();
		} else {
			api.recruiter.getTalentsProject(talentId)
				.then((response) => {
					setProjectPreview(response.data.result.splice(0, 5));
				})
				.catch((error) => console.log('error', error))
				.finally();
		}
	}, [talentId, user?.role]);

	const breadCrumbProp = location.state?.breadcrumb || [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'Profile',
			link: '',
		},
	];

	function getUser() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response.data.result;
			setUser(result);
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.getTalent(talentId).then(successHandler).catch(errorHandler).finally(onFinal);
	}

	// eslint-disable-next-line
	useEffect(getUser, []);

	return (
		<div id="Recruiter_View_Talent_Profile_Main_Container">
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="container-01">
					<div className="row mb-5">
						<div className="first-col col-12 mb-5 mb-lg-0">
							<div className="outline-wrap" style={{ marginBottom: '40px' }}>
								<BreadCrumb context={breadCrumbProp} />

								<div className="section section-a py-4">
									{!isLoading && (
										<div className={`section-a d-lg-flex align-items-center justify-content-between`}>
											<div className="d-flex flex-column flex-lg-row align-items-center gap-3 mb-4 mb-lg-0">
												<div className="con-img">
													<div className="user-img">
														{!loadingImage && <img src={user?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="" />}

														{loadingImage && <div className={'animated-bg'}></div>}
													</div>
												</div>

												<div className="user-context d-flex flex-column align-items-center align-items-lg-start text-center gap-2">
													<h1>
														{user?.first_name && service.titleCase(user?.first_name)} {user?.last_name && service.titleCase(user?.last_name)}
													</h1>
													<h2 title={user?.title}>{user?.title ? service.truncateText(user?.title, 26) : ''}</h2>
													<p className="d-flex align-items-center gap-2">
														<i className="fa-solid fa-location-dot"></i> {user?.location?.resident_state && service.titleCase(user?.location?.resident_state)}, {user?.location?.resident_country && service.titleCase(user?.location?.resident_country)}
													</p>
												</div>
											</div>
											<Buttons job={jobId} talent={talentId}/>
										</div>
									)}

									<div className="con-animated-bg">{isLoading && [1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
								</div>

								{!isLoading && (
									<div className="section section-b py-4">
										<h2 className="title mb-3">About {user?.first_name && service.titleCase(user?.first_name)}</h2>
										<p>{user?.talent_bio && service.transform(user?.talent_bio)}</p>
									</div>
								)}

								{!isLoading && (
									<div className="section section-c py-4">
										<div className="heading d-flex align-items-center justify-content-between mb-3">
											<h2 className="title">Projects</h2>

											{projectPreview.length > 7 && (
												<Link to="/dashboard/projects">
													<p>See More</p>
												</Link>
											)}
										</div>

										<div className="body d-flex align-items-center gap-3">
											{projectPreview.length > 0 ? (
												projectPreview.map((project) => {
													const coverImage = project.project_cover_image?.href_link;
													return <ProjectCard key={project._id} title={project.project_title} cover={coverImage} id={project._id} />;
												})
											) : (
												<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
													<img src={'/img/svg/noproject.svg'} alt="no project" style={{ width: '70px' }} />
													<h5 className="fw-bold">Talent Has No Project added</h5>
												</div>
											)}
										</div>
										{projectPreview.length > 7 && (
											<div className="d-flex justify-content-center mt-4">
												<DotPagination index={0} />
											</div>
										)}
									</div>
								)}
								{/* Experience */}
								{!isLoading && (
									<div className="section section-e py-4">
										<div className="heading d-flex align-items-center gap-2">
											<img src="/img/svg/experience.svg" alt="experience" style={{ width: '25px' }} />
											<h2 className="title">Experience</h2>
										</div>
										{user?.experience?.length > 0 ? (
											<div>
												{user?.experience?.length > 0 &&
													user?.experience.map((x, y) => (
														<div key={y}>
															<div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4">
																<div className="experience-block mb-4 mb-lg-0">
																	<div className="one d-flex align-items-center gap-2">
																		<h2>{x?.job_title || `job title`}</h2>

																		<div className="tip">{x.contract_type || ``}</div>
																	</div>
																	<p className="two">{x.company || `company`}</p>
																	<p className="three">
																		<p className="three">
																			{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;
																			{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
																			{x.end_date === 'present' ? (
																				'Present'
																			) : (
																				<>
																					{service.monthNames()[new Date(x.end_date).getMonth()]}&nbsp;
																					{new Date(x.end_date).getFullYear()}
																				</>
																			)}
																		</p>
																	</p>
																</div>
															</div>
														</div>
													))}
											</div>
										) : (
											<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
												<img src={'/img/svg/noexp.svg'} alt="no experience" style={{ width: '70px' }} />
												<h5 className="fw-bold">Talent Has No Work Experience added</h5>
											</div>
										)}
									</div>
								)}
								{/* Education */}
								{!isLoading && (
									<div className="section section-f py-4">
										<div className="heading d-flex align-items-center gap-2">
											<img src="/img/svg/education.svg" alt="education" style={{ width: '25px' }} />
											<h2 className="title">Education</h2>
										</div>
										{user?.education?.length > 0 ? (
											<div>
												{user?.education?.length > 0 &&
													user?.education.map((x, y) => (
														<div key={y}>
															<div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4">
																<div className="experience-block">
																	<div className="one d-flex align-items-center gap-2">
																		<h2>{x?.school}</h2>
																	</div>
																	<p className="two">{x?.degree}</p>
																	<p className="three">
																		{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
																		{x.end_date === 'present' ? 'Present' : <>{x.end_date ? new Date(x.end_date).getFullYear() : `Date`}</>}
																	</p>
																</div>
															</div>
														</div>
													))}
											</div>
										) : (
											<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
												<img src={'/img/svg/noedu.svg'} alt="no experience" style={{ width: '70px' }} />
												<h5 className="fw-bold">Talent Has No Education added</h5>
											</div>
										)}
									</div>
								)}
								{/* Certificates */}
								{!isLoading && (
									<div className="section section-g py-4">
										<div className="heading d-flex align-items-center gap-2">
											<img src="/img/svg/certificate.svg" alt="certificate" style={{ width: '25px' }} />
											<h2 className="title">Certificates</h2>
										</div>

										{user?.certificates?.length > 0 ? (
											<div>
												{
													<section className="py-2 row">
														{user?.certificates?.length > 0 &&
															user?.certificates.map((x, y) => (
																<div key={y} className="col-lg-6">
																	<h2 className="text-capitalize" style={{ fontSize: '1.2rem' }}>
																		{x.issuer}
																	</h2>
																	<div className="d-flex justify-content-between align-items-center border border-4 border-dark mb-2 mt-1 py-3 px-3" style={{ borderRadius: '10px' }}>
																		<div style={{ width: '80%' }}>
																			<p className="text-truncate" style={{ maxWidth: '90%' }}>
																				{x.file_name}
																			</p>
																			<p className="text-thin" style={{ fontWeight: '500', fontSize: '.8rem' }}>
																				Uploaded:{' '}
																			</p>
																		</div>
																	</div>
																	<h5 className="sub-title mt-1">
																		Year issued:{' '}
																		{new Date(x.issued_date).toLocaleDateString('en-US', {
																			year: 'numeric',
																		})}
																	</h5>
																</div>
															))}
													</section>
												}
											</div>
										) : (
											<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center ">
												<img src={'/img/svg/noexp.svg'} alt="no experience" style={{ width: '70px' }} />
												<h5 className="fw-bold">Talent Has No Certificate added</h5>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TalentProfile;
