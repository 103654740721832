import Button from 'components/buttons/Button';
import LoginForm from 'components/forms/login-form/LoginForm';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import service from 'services';
import { auth } from 'services/api/auth.js';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
// import Previous from '../onboarding/talent/previous/Previous';
import Context from './context/Context';
import './css/Register.style.sass';
import './css/Register.css';

import { useNavigate } from 'react-router-dom';

function Register() {
	service.setPageTitle('Join us today!');
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [query] = useSearchParams();

	const { rightContext, sidebarContext } = Context();

	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const modalHandler = () => {
		document.getElementById('freelancer-modal').classList.add('show');
		document.getElementById('freelancer-modal').style.display = 'block';
	};

	const handleRecruiterSubmission = (data) => {
		const { firstName, lastName, email, password } = data;
		const mapper = {
			first_name: firstName,
			last_name: lastName,
			email,
			password,
		};

		setIsLoading(true);

		function successResponse(response) {
			setIsLoading(false);
			setErrorMsg('');
			modalHandler();
			service.setTokensLocalStorage(response?.data?.token);
		}

		function errorResponse(err) {
			setIsLoading(false);
			setErrorMsg(err?.data?.result);
			setTimeout(() => setErrorMsg(''), service.errorMilliSecond);
			console.error(err);
		}

		auth.registerRecruiter(mapper).then(successResponse).catch(errorResponse);
	};

	const handleTalentSubmission = (data) => {
		const { firstName, lastName, email, password } = data;
		const mapper = {
			first_name: firstName,
			last_name: lastName,
			email,
			password,
			full_time_decision: 'full-time',
		};

		setIsLoading(true);

		function successResponse({ data: { token } }) {
			setIsLoading(false);
			setErrorMsg('');
			modalHandler();
			service.setTokensLocalStorage(token);
		}

		function errorResponse(err) {
			setIsLoading(false);
			setErrorMsg(err.data.result);
			setTimeout(() => setErrorMsg(''), service.errorMilliSecond);
			console.error(err);
		}

		auth.registerTalent(mapper).then(successResponse).catch(errorResponse);
	};

	const handleTalentSubmissionModal = () => {
		// navigate({
		// 	search: `?${service.page}talent&${service.section}001`,
		// });
	};

	const handleFreelancerSubmissionModal = () => {
		// navigate({
		// 	search: `?${service.page}freelancer&${service.section}001`,
		// });
	};

	const handleRecruiterSubmissionModal = () => {
		// navigate({
		// 	search: `?${service.page}recruiter&${service.section}001`,
		// });
	};

	const handleFreelancerSubmission = (data) => {
		const { firstName, lastName, email, password } = data;
		const mapper = {
			first_name: firstName,
			last_name: lastName,
			email,
			password,
		};

		setIsLoading(true);

		function successResponse({ data: { token } }) {
			setIsLoading(false);
			setErrorMsg('');
			modalHandler();
			service.setTokensLocalStorage(token);
		}

		function errorResponse(err) {
			setIsLoading(false);
			setErrorMsg(err.data.result);
			setTimeout(() => setErrorMsg(''), service.errorMilliSecond);
			console.error(err);
		}

		auth.registerFreelancer(mapper).then(successResponse).catch(errorResponse);
	};

	return (
		<div id="Register_Main_Container" ref={top}>
			<Navbar rightContent={rightContext} sidebarContent={sidebarContext} />

			<div className="con-margin-height">
				<div className="con-context " style={{ backgroundColor: '#fefefe' }}>
					{query.get('tab') === service.TALENT && <ContextForm noModals isLoading={isLoading} errorMsg={errorMsg} callback={handleTalentSubmission} modalCallback={handleTalentSubmissionModal} />}

					{query.get('tab') === service.FREELANCER && <ContextForm noModals isLoading={isLoading} errorMsg={errorMsg} callback={handleFreelancerSubmission} modalCallback={handleFreelancerSubmissionModal} />}

					{query.get('tab') === service.RECRUITER && <ContextForm noModals isLoading={isLoading} errorMsg={errorMsg} callback={handleRecruiterSubmission} modalCallback={handleRecruiterSubmissionModal} />}

					{!query.get('tab') && <Default />}
				</div>
			</div>

			<Footer />
		</div>
	);
}

function ContextForm(props) {
	return (
		<div className="container-01">
		
			<div className="mt-5 bg-white p-3 py-lg-4 px-lg-5 " style={{ boxShadow: '0px 10px 30px 0px #00000026' }}>
				<LoginForm ForRegister {...props} />
			</div>
		</div>
	);
}

function Default() {
	const [redirect, setRedirect] = useState('');

	const navigate = useNavigate();
	const handleChange = (e) => {
		setRedirect(`/register?tab=${e.target.value}`);
	};
	function handleRedirect() {
		navigate(redirect);
	}

	return (
		<div className="d-flex flex-column align-items-center">
			<div className="con-header mb-3 d-flex flex-column  container-01">
				<h2>Welcome! Describe your intentions</h2>
				<p className="mt-3">This would let us know how to customize your experience when searching for opportunities</p>
			</div>

			<div>
				<div className="con-select d-lg-flex align-items-center gap-5">
					<div className="d-flex flex-column align-items-center gap-3 my-3  position-relative ">
						<input type="radio" id="freelancer" name="radio-group" class="radio-input" value="freelancer" onChange={handleChange} />
						<label htmlFor="freelancer" class="radio-label">
							<span class="radio-inner-circle"></span>
							<div className="main-selector mb-3 mb-lg-0">
								<div className="heading">
									<h2>Talent</h2>
								</div>

								<div className="body">
									<div className="img">
										<img src="/img/register-image-2.png" alt="" />
									</div>
								</div>
							</div>
						</label>

						<div className="cta w-100">
							<p>I am a Freelancer</p>
						</div>
					</div>
					<div className="d-flex flex-column align-items-center gap-3 my-3 position-relative">
						<input type="radio" id="talent" name="radio-group" class="radio-input" value="talent" onChange={handleChange} />
						<label htmlFor="talent" class="radio-label">
							<span class="radio-inner-circle"></span>

							<div className="main-selector mb-3 mb-lg-0">
								<div className="heading">
									<h2>Talent</h2>
								</div>

								<div className="body">
									<div className="img">
										<img src="/img/register-image-2.png" alt="" />
									</div>
								</div>
							</div>
						</label>

						<div className="cta w-100">
							<p>I am looking for a job</p>
						</div>
					</div>
					<div className="d-flex flex-column align-items-center gap-3 my-3 position-relative">
						<input type="radio" id="recruiter" name="radio-group" class="radio-input" value="recruiter" onChange={handleChange} />
						<label htmlFor="recruiter" class="radio-label">
							<span class="radio-inner-circle"></span>
							<div className="main-selector">
								<div className="heading">
									<h2>Recruiter</h2>
								</div>

								<div className="body">
									<div className="img">
										<img src="/img/register-image-1.png" alt="" />
									</div>
								</div>
							</div>
						</label>

						<div className="cta w-100">
							<p>I am looking for hire</p>
						</div>
					</div>
				</div>

				<div className="my-3">
					<Button
						text={
							<>
								<h1>Next</h1>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						onClick={handleRedirect}
					/>
				</div>
			</div>
		</div>
	);
}

export default Register;
