import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import './css/SectionC.style.sass';
import { Country, State } from 'country-state-city';
import Tab from 'components/buttons/tabs/Tabs';

function SectionC() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({ email: '' });
	// eslint-disable-next-line no-unused-vars
	const [countries, setCountries] = useState(Country.getAllCountries());
	const [statesOfSelectedCountry, setStatesOfSelectedCountry] = useState([]);

	const [form, setForm] = useState({
		talent_bio: '',
		country: '',
		state: '',
		citizenship: '',
		phone_number: '',
		english_proficiency: '',
		gender: '',
	});

	function handleGenderSelection(gender) {
		setForm((prevFilters) => ({
			...prevFilters,
			gender,
		}));
	}

	const handleCountryChange = (event) => {
		const selectedCountryName = event.target.value;
		const selectedCountry = countries.find((country) => country.name === selectedCountryName);
		const { name: country } = selectedCountry;
		if (selectedCountry) {
			setForm({ ...form, country: country });
			// Update the states based on the selected country
			const states = State.getStatesOfCountry(selectedCountry.isoCode);
			setForm((prevForm) => ({ ...prevForm, state: '' })); // Reset the state when changing the country
			setStatesOfSelectedCountry(states);
		} else {
			setForm({ ...form, country: '', state: '' });
			setStatesOfSelectedCountry([]);
		}
	};

	const handleStateChange = (event) => {
		const selectedStateName = event.target.value;
		const selectedState = statesOfSelectedCountry.find((state) => state.name === selectedStateName);

		if (selectedState) {
			setForm({ ...form, state: selectedState.name });
		} else {
			setForm({ ...form, state: 'No state' });
		}
	};

	console.log(form);

	const [formErrors, setFormErrors] = useState(form);

	function handleOnChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		setForm({ ...form, [`${name}`]: value });
		setFormErrors({ ...formErrors, [`${name}`]: `` });
	}

	function configFields(form) {
		form.phone_number = Number(form.phone_number);
		form.location = { resident_country: form.country, resident_state: form.state, citizenship: form.citizenship };

		delete form.country;
		delete form.state;
		delete form.citizenship;
	}

	function hasErrors() {
		for (const field in form) {
			if (!form[field]) {
				validationHandler(field);
				console.warn(`{${field}} field is required`);

				return true;
			} else if (validations()) {
				return true;
			}
		}

		return false;
	}

	function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.forms[0].elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	}

	function validations() {
		const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,8}$/im;
		if (!regex.test(form[`phone_number`])) {
			validationHandler('phone_number', 'phone number is invalid');

			return true;
		}
		return false;
	}

	function handleOnSubmit() {
		if (!hasErrors()) {
			configFields(form);
			setIsLoading(true);

			function successHandler() {
				setIsLoading(false);
				navigate({ search: `?section=4` });
			}

			function errorHandler(response) {
				setIsLoading(false);
				console.error(response);
			}

			api.talents.profile.update(form).then(successHandler).catch(errorHandler);
		}
	}

	function fetchUser() {
		function successHandler(response) {
			const result = response.data.result;
			setUser(result);
		}

		function errorHandler(response) {
			if (response.data.success === false) {
				window.location.reload();
			}
		}

		api.talents.profile.get().then(successHandler).catch(errorHandler);
	}

	useEffect(fetchUser, []);

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<p className="mb-3 d-flex align-items-center gap-3">Basic Info</p>

							<h2 className="display">Let's get to know you better!</h2>

							<p className="mb-3">Tell us who you are!</p>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="60" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01">
				<div className="con-form">
					<div className="form">
						<form>
							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/person1.svg" alt="about you" className="svg" />
									<h2>About you</h2>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex ">
											<label>Gender</label>
											<label className="error-msg mb-3 col-lg-6">{formErrors.gender}</label>
										</div>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Male', 'Female', 'Others'].map((x, y) => (
												<Tab primaryOutline darkBg={form.gender === x.toLowerCase()} context={x} key={y} callback={() => handleGenderSelection(x.toLowerCase())} />
											))}
										</div>
									</div>
								</div>
								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex flex-column ">
											<label>Bio</label>

											<label className="error-msg mb-3 col-lg-6">{formErrors.talent_bio}</label>
										</div>

										<textarea
											style={{
												height: '200px',
												width: '100%',
												// maxWidth: '800px',
											}}
											placeholder="Enter your bio"
											name="talent_bio"
											value={form.talent_bio}
											onChange={handleOnChange}
											className={formErrors.talent_bio ? 'error' : ''}></textarea>
									</div>
								</div>
							</div>

							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/locationsvg.svg" alt="location" className="svg" />

									<h2>Your Location</h2>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>Country of Residence</label>

											<label className="error-msg mb-3">{formErrors.country}</label>
										</div>

										<select name="country" value={form.country} onChange={handleCountryChange} className={formErrors.country ? 'error' : ''}>
											<option value="">Enter country of residence</option>
											{countries.map((country) => {
												return (
													<option key={country.name} value={country.name}>
														{country.name}
													</option>
												);
											})}
										</select>
									</div>

									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>State</label>

											<label className="error-msg mb-3">{formErrors.state}</label>
										</div>

										<select name="state" value={form.state} onChange={handleStateChange} className={formErrors.state ? 'error' : ''}>
											<option value="">Enter State</option>
											{statesOfSelectedCountry.length > 0 ? (
												statesOfSelectedCountry.map((state) => (
													<option key={state.isoCode} value={state.name}>
														{state.name}
													</option>
												))
											) : (
												<option key={'no_state'} value="No State">
													No State
												</option>
											)}
										</select>
									</div>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>Country of citizenship</label>

											<label className="error-msg mb-3">{formErrors.citizenship}</label>
										</div>

										<select name="citizenship" value={form.citizenship} onChange={handleOnChange} className={formErrors.citizenship ? 'error' : ''}>
											<option value="">Enter country of citizenship</option>
											{countries.map((country) => {
												return (
													<option key={country.name} value={country.name}>
														{country.name}
													</option>
												);
											})}
										</select>
									</div>

									<i className="control-form"></i>
								</div>
							</div>

							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/contact1.svg" alt="contact you" className="svg" />

									<h2>How can we reach you?</h2>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>Phone Number</label>

											<label className="error-msg mb-3">{formErrors.phone_number}</label>
										</div>

										<input type="tel" placeholder="Enter your phone number" name="phone_number" value={form.phone_number} onChange={handleOnChange} className={formErrors.phone_number ? 'error' : ''} />
									</div>

									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>Email Address</label>
										</div>

										<input type="email" placeholder="example@email.com" value={user.email} disabled />
									</div>
								</div>
							</div>

							<div className="mb-4">
								<div className="con-title d-flex align-items-center gap-3 mb-3">
									<img src="/img/svg/speaker.svg" alt="english" className="svg" />

									<h2>How good is your English?</h2>
								</div>

								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex justify-content-between">
											<label>English Proficiency</label>

											<label className="error-msg mb-3">{formErrors.english_proficiency}</label>
										</div>

										<select name="english_proficiency" value={form.english_proficiency} onChange={handleOnChange} className={formErrors.english_proficiency ? 'error' : ''}>
											<option value="">Choose Proficiency</option>
											<option value="beginner">Beginner</option>
											<option value="intermediate">Intermediate</option>
											<option value="advanced">Advanced</option>
										</select>
									</div>

									<i className="control-form"></i>
								</div>
							</div>

							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0 d-none">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>One Step back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleOnSubmit} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionC;
