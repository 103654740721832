import './css/SectionC.css';
import VideoCarousel from 'components/videoCarosel';

function SectionC() {
	const videoSlides = [
		{ src: `${process.env.PUBLIC_URL}/video1.mp4`, type: 'video/mp4', step: 1, stepText: 'Register on our platform' },
		{ src: `${process.env.PUBLIC_URL}/video2.mp4`, type: 'video/mp4', step: 2, stepText: 'Build your bio' },
		{ src: `${process.env.PUBLIC_URL}/video3.mp4`, type: 'video/mp4', step: 3, stepText: 'Choose keywords that lead potential recruiters to you' },
		{ src: `${process.env.PUBLIC_URL}/video4.mp4`, type: 'video/mp4', step: 4, stepText: 'Achievements (upload evidences where necessary)' },
		{ src: `${process.env.PUBLIC_URL}/video5.mp4`, type: 'video/mp4', step: 5, stepText: 'Be verified' },
	];

	console.log('Video Slides:', videoSlides);
	return (
		<div id="SectionC_Main_Container">
			<div className="con-section px-3 px-md-0">
				<div className="con-box container-01">
					<div className="my-5 easy-process">
						<VideoCarousel slides={videoSlides} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionC;
