import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import ProjectCard from 'components/cards/project-card/ProjectCard';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import { navbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp';
import './css/Project.style.sass';
import api from 'services/api';
import Button from 'components/buttons/Button';
import { useNavigate } from 'react-router-dom';

function Project() {
	service.setPageTitle('My Projects');
	const userType = service.getUserType();
	const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [projects, setProjects] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (userType === 'talent') {
			api.talents.projects
				.getAllProjects()
				.then((response) => {
					setProjects(response.data.result);
				})
				.catch((error) => console.log('error', error))
				.finally(() => setIsLoading(false));
		} else {
			api.freelancers.projects
				.getAllProjects()
				.then((response) => {
					setProjects(response.data.result);
				})
				.catch((error) => console.log('error', error))
				.finally(() => setIsLoading(false));
		}
	}, [userType]);

	function deleteProject(id) {
		setIsLoading(true);

		function success() {
			setProjects((prevProjects) => prevProjects.filter((project) => project._id !== id));
		}

		if (userType === 'talent') {
			api.talents.projects
				.deleteProject(id)
				.then(success)
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			api.freelancers.projects
				.deleteProject(id)
				.then(success)
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}

	const breadCrumbProp = [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'My Projects',
			link: '',
		},
	];

	return (
		<div id="Project_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap">
						<div className="con-header mx-4 py-4">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Projects</h2>

							<div className="body">
								{isLoading ? (
									[1, 1, 1, 1, 1, 1].map((x, y) => <div className="animated-bg" style={{ width: 'auto', height: '200px' }} key={y}></div>)
								) : (
									<>
										{projects.length > 0 &&
											projects.map((project) => {
												const coverImage = project.project_cover_image?.href_link;
												return <ProjectCard key={project._id} title={project.project_title} cover={coverImage} id={project._id} width="100%" handleDelete={() => deleteProject(project._id)} />;
											})}
									</>
								)}
							</div>
							{!isLoading && projects?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src={'/img/svg/noedu.svg'} alt="no experience" style={{ width: '70px' }} />
									<h5 className="fw-bold">No Projects yet</h5>
									<p className="fw-normal">Create a project now</p>
									<Button primaryNull text={'Create A Project'} onClick={() => navigate('/dashboard/projects/create')} />
								</div>
							)}
						</div>

						{projects.length > 4 && (
							<div className="footer">
								<IndexPagination />
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Project;
